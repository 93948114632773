import { Fragment, useContext, useEffect, useRef, useState } from "react";
import removeWhite from "../assets/icons/remove-white.svg";
import removeCross from "../assets/icons/remove-cross-white.svg";
import { TextInput } from "./TextInput";
import { ActivityContext, ModalContext } from "../App";
import { ActivityItem } from "./ActivityItem";
import css from "./colorPicker.css";
import { Listbox, Transition } from "@headlessui/react";
import { SetRecurringPatternModal } from "./SetRecurringPatternModal";

export function ContextMenu({
  block,
  left,
  top,
  open,
  setOpen,
  onRemove,
  onTitleChange,
  onRemoveActivity,
  children,
  onColorChange,
  onRecurringChange,
  onChangeAsRecurringChange,
  onPatternChange,
}) {
  const [activities, setActivities] = useContext(ActivityContext);
  const blockActivity = activities.find(
    (activity) => activity.id === block.activityId
  );

  const [color, setColor] = useState(block.color);
  const [colorUpdated, setColorUpdated] = useState(false);
  const [recurringPatternModalOpen, setRecurringPatternModalOpen] =
    useState(false);

  const menuRef = useRef();

  const handleClick = (event) => {
    if (menuRef?.current && !menuRef?.current?.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  const handleRadioChange = (option) => {
    onChangeAsRecurringChange(option);
  };

  if (!open) {
    return <></>;
  }

  const handleTitleChange = (value) => {
    onTitleChange(value);
  };
  const handleRemoveActivity = () => {
    onRemoveActivity();
  };
  const handleChangeAsRecurringChange = (value) => {
    onChangeAsRecurringChange(value);
  };
  const handlePatternChange = (value) => {
    onPatternChange(value);
  };
  const handleColorChange = (value) => {
    setColor(value);
    setColorUpdated(true);
  };
  const handleColorBlur = () => {
    if (colorUpdated) onColorChange(color);
  };

  return (
    <div
      ref={menuRef}
      className="absolute w-56 border rounded text-white bg-dark z-[999] pointer-events-auto"
      style={{ top, left }}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      <ul className="flex gap-2">
        <li className="m-1 flex items-start">
          <ColorPicker
            color={color}
            onColorChange={handleColorChange}
            onBlur={handleColorBlur}
          />
        </li>

        <li
          role="button"
          className="p-1 ml-auto cursor-pointer"
          onClick={onRemove}
        >
          <img className="h-4" src={removeWhite} alt="" />
        </li>
      </ul>

      <div className="p-3 space-y-3">
        <TextInput
          title={"Title"}
          onValueChange={handleTitleChange}
          value={block.title}
        />
        {blockActivity && (
          <div className="flex gap-1 items-center">
            <div className="flex-grow">
              <ActivityItem
                id={blockActivity.id}
                properties={{
                  color: blockActivity.color,
                  title: blockActivity.title,
                }}
                showOptions={false}
              />
            </div>
            <div
              role="button"
              className="cursor-pointer"
              onClick={handleRemoveActivity}
            >
              <img className="h-4" src={removeCross} alt="" />
            </div>
          </div>
        )}

        <hr className="border-lightDark" />

        <CheckboxInput
          title={"Recurring"}
          onValuehange={onRecurringChange}
          value={block.isRecurrance}
        />
        {block.isRecurrance && (
          <ChangeAsRadio
            title={"Block to update"}
            onChange={handleRadioChange}
            selectedOption={block.selectedRadioOption ?? "single"}
          />
        )}

        {block.isRecurrance && (
          <SelectPattern
            selected={block.recurrencePattern}
            onSelectedChange={handlePatternChange}
          />
        )}
      </div>
    </div>
  );
}

function ColorPicker({ color, onColorChange, onBlur }) {
  const handleColorChange = (event) => {
    onColorChange(event.target.value);
  };

  return (
    <div className="z-40">
      <input
        type="color"
        id="color"
        name="color"
        value={color}
        onChange={handleColorChange}
        onBlur={onBlur}
      />
    </div>
  );
}

function CheckboxInput({ title, onValuehange, value = false }) {
  const onValueChange = (input) => {
    onValuehange(input.target.checked);
  };
  return (
    <div className="flex items-start mb-6">
      <div className="flex items-center h-5">
        <input
          type="checkbox"
          value={value}
          checked={value}
          onChange={onValueChange}
        />
      </div>
      <label className="ml-2 text-sm font-medium text-gray-300">{title}</label>
    </div>
  );
}

function ChangeAsRadio({ selectedOption, onChange }) {
  const handleChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <div className="mt-5">
      <h4>Make changes to</h4>
      <div className="radio">
        <label className="flex gap-3">
          <input
            type="radio"
            value="single"
            checked={selectedOption === "single"}
            onChange={handleChange}
          />
          This block
        </label>
        <label className="flex gap-3">
          <input
            type="radio"
            value="future"
            checked={selectedOption === "future"}
            onChange={handleChange}
          />
          This and future blocks
        </label>
      </div>
      <div className="radio">
        <label className="flex gap-3">
          <input
            type="radio"
            value="all"
            checked={selectedOption === "all"}
            onChange={handleChange}
          />
          All blocks
        </label>
      </div>
    </div>
  );
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function SelectPattern({ children, selected, onSelectedChange }) {
  const [recurrencePattern, setRecurrencePattern] = useState([
    "Daily",
    "Weekly",
    "Yearly",
    "Weekdays",
  ]);

  return (
    <Listbox value={selected} onChange={onSelectedChange}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium leading-6 text-white">
            Recurrence Pattern
          </Listbox.Label>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-lightDark py-1.5 pl-3 pr-10 text-left text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
              <span className="flex items-center">
                <span className="ml-3 block truncate">{selected}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2"></span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-lightDark  py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {recurrencePattern.map((pattern) => (
                  <Listbox.Option
                    key={pattern}
                    className={({ active }) =>
                      classNames(
                        "text-white relative cursor-default select-none py-2 pl-3 pr-9 hover:bg-dark"
                      )
                    }
                    value={pattern}
                  >
                    {pattern}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
