import { Menu, Transition } from "@headlessui/react";
import menuIconBlack from "../assets/icons/hamburger-menu-black.svg";
import menuIcon from "../assets/icons/hamburger-menu-white.svg";
import { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { auth } from "../firebase";
import { signOut } from "firebase/auth";
import { UserContext } from "../App";

export function HeaderNavigationMenu() {
  const [user, setUser] = useContext(UserContext);

  const handleLogout = () => {
    signOut(auth);
  };
  return (
    <div>
      <Menu as="div" className="relative text-left ml-auto flex align-middle">
        <Menu.Button className="w-full justify-center gap-x-1.5 px-3 py-2 text-sm font-semibold text-gray-900 ring-lightDark">
          <img
            className="w-5 dark:hidden"
            src={menuIconBlack}
            alt="menu icon"
          />
          <img
            className="w-5 hidden dark:block"
            src={menuIcon}
            alt="menu icon"
          />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-[#515151] text-whitefu shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <Link className="block px-4 py-2" to="/home">
                    Home
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link className="block px-4 py-2" to="/timeblocks">
                    TimeBlocks
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link className="block px-4 py-2" to="/activities">
                    Activities
                  </Link>
                )}
              </Menu.Item>
              {user && (
                <Menu.Item>
                  {({ active }) => (
                    <div className="block px-4 py-2">
                      <button onClick={handleLogout}>Log Out</button>
                    </div>
                  )}
                </Menu.Item>
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
