import { Listbox, Transition } from "@headlessui/react";
import Modal from "./Modal";
import { Fragment, useState } from "react";
import { TextInput } from "./TextInput";

export default function ConfirmRecurringOptionModal({
  data,
  onSuccess,
  onCancel,
}) {
  const handleSuccess = () => {
    onSuccess(data, option);
  };
  const handleCancel = () => {
    onCancel(data);
  };

  const [option, setOption] = useState("future");

  const handleChange = (event) => {
    setOption(event.target.value);
  };

  return (
    <Modal
      title={null}
      successText={"OK"}
      onSuccess={handleSuccess}
      onCancel={handleCancel}
    >
      <div className="text-white">
        <h3>Confirm recurring action</h3>
        <div className="mt-5">
          <div className="radio">
            <label className="flex gap-3">
              <input
                type="radio"
                value="future"
                checked={option === "future"}
                onChange={handleChange}
              />
              This and future blocks
            </label>
          </div>
          <div className="radio">
            <label className="flex gap-3">
              <input
                type="radio"
                value="all"
                checked={option === "all"}
                onChange={handleChange}
              />
              All blocks
            </label>
          </div>
        </div>
      </div>
    </Modal>
  );
}
