import { Listbox, Transition } from "@headlessui/react";
import Modal from "./Modal";
import { Fragment, useState } from "react";

export function SetRecurringPatternModal({
  onPatternChange,
  onSetSuccess,
  onCancel,
}) {
  const [selectedPattern, setSelectedPattern] = useState("Daily");
  const handlePatternChange = (pattern) => {
    setSelectedPattern(pattern);
  };
  const handleSuccess = () => {
    onSetSuccess(selectedPattern);
  };
  const handleCancel = () => {
    onCancel();
    setSelectedPattern("Daily");
  };
  return (
    <Modal
      title={"Set Recurring Pattern"}
      successText={"Save"}
      onSuccess={handleSuccess}
      onCancel={handleCancel}
    >
      <div className="pb-40">
        <SelectPattern
          selected={selectedPattern}
          onSelectedChange={handlePatternChange}
        />
      </div>
    </Modal>
  );
}

export function SelectPattern({ children, selected, onSelectedChange }) {
  const [recurrencePattern, setRecurrencePattern] = useState([
    "Daily",
    "Weekly",
    "Yearly",
    "Weekdays",
  ]);

  return (
    <Listbox value={selected} onChange={onSelectedChange}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium leading-6 text-white">
            Recurrence Pattern
          </Listbox.Label>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-lightDark py-1.5 pl-3 pr-10 text-left text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
              <span className="flex items-center">
                <span className="ml-3 block truncate">{selected}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2"></span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-lightDark  py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {recurrencePattern.map((pattern) => (
                  <Listbox.Option
                    key={pattern}
                    className={({ active }) =>
                      classNames(
                        "text-white relative cursor-default select-none py-2 pl-3 pr-9 hover:bg-dark"
                      )
                    }
                    value={pattern}
                  >
                    {pattern}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
