import { doBlocksOverlap, doBlocksOverlapText } from "./Overlaping";

export const getTreeOld = (blocks, lanes) => {
  const blocksCopy = blocks.map((block) => ({
    ...block,
    children: [],
    parents: [],
    depth: 1,
    maxLen: 0,
    hasTextOverlapingChildren: false,
    isOverlapingParentText: false,
  }));
  let tree = { children: [] };
  for (let i = 0; i < lanes.length; i++) {
    const lane = lanes[i];
    const prevLane = lanes[i - 1];
    if (i === 0) {
      tree.children = blocksCopy.filter((bCopy) =>
        lane.some((bLane) => bLane.id === bCopy.id)
      );
      continue;
    }

    for (const b of lane) {
      const parents = prevLane.filter((prevBlock) =>
        doBlocksOverlap(b, prevBlock)
      );
      const parentCopies = blocksCopy.filter((bCopy) =>
        parents.some((parent) => parent.id === bCopy.id)
      );
      const block = blocksCopy.find((bCopy) => bCopy.id === b.id);
      parentCopies.forEach((parent) => {
        parent.children.push(block);
      });
      block.parents = parentCopies;
    }
  }

  calculateDepth(tree);
  calculateMasterParent(blocksCopy);

  return { tree, treeBlocks: blocksCopy };
};

export const getTree = (blocks, lanes) => {
  return getTreeV3(blocks, lanes);
  // return getTreeOld(blocks, lanes);
};

export const getTreeV3 = (blocks, lanes) => {
  const blocksCopy = blocks.map((block) => ({
    ...block,
    children: [],
    parents: [],
    depth: 1,
    maxLen: 0,
    hasTextOverlapingChildren: false,
    isOverlapingParentText: false,
    grandChildren: [],
    grandParents: [],
    parentsOverlappingTexts: [],
  }));
  let tree = { children: [] };

  for (let i = 0; i < lanes.length; i++) {
    const laneBlocks = lanes[i];
    const prevLane = lanes[i - 1];
    if (i === 0) {
      tree.children = blocksCopy.filter((bCopy) =>
        laneBlocks.some((bLane) => bLane.id === bCopy.id)
      );
      // continue;
    }

    for (const laneBlock of laneBlocks) {
      const block = blocksCopy.find((bCopy) => bCopy.id === laneBlock.id);

      //list all younger relatives as grandchildren. including children
      for (let j = i + 1; j < lanes.length; j++) {
        const laterLaneBlocks = lanes[j];
        const blocksOverlapping = laterLaneBlocks.filter((b) =>
          doBlocksOverlap(laneBlock, b)
        );
        if (blocksOverlapping.length === 0) continue;
        const blocksOverlappingCopies = blocksCopy.filter((bCopy) =>
          blocksOverlapping.some((b) => b.id === bCopy.id)
        );
        for (const bCopy of blocksOverlappingCopies) {
          block.grandChildren.push(bCopy);
        }
      }
    }
  }

  for (const block of blocksCopy) {
    for (const grandChild of block.grandChildren) {
      const otherGrandChildren = block.grandChildren.filter(
        (gc) => gc.id !== grandChild.id
      );

      if (
        !otherGrandChildren.some((gc) =>
          gc.grandChildren.some((gcc) => gcc.id === grandChild.id)
        )
      ) {
        block.children.push(grandChild);
        grandChild.parents.push(block);
      } else {
        grandChild.grandParents.push(block);
      }
    }
  }

  //add missing grandChildren that is blocks overlapping a grandchild but not itself
  for (const block of blocksCopy) {
    for (const parent of block.parents) {
      const missedGrandChildren = block.grandChildren.filter((gc) => {
        return !parent.grandChildren.some((pgc) => pgc.id === gc.id);
      });
      parent.grandChildren.push(...missedGrandChildren);
    }
  }

  calculateDepth(tree);
  calculateMasterParent(blocksCopy);
  return { tree, treeBlocks: blocksCopy };
};

function calculateDepth(treeRoot) {
  // Each element in the stack is an array [node, depth]
  let stack = [[treeRoot, 1]];
  let nodeList = []; // List of nodes, will be used in the second pass
  while (stack.length) {
    let [node, depth] = stack.pop();
    node.depth = depth;
    nodeList.push(node);

    for (let child of node.children) {
      stack.push([child, depth + 1]);
    }
  }

  while (nodeList.length) {
    let node = nodeList.pop();
    if (node.children.length > 0) {
      let childMaxLens = node.children.map((child) => child.maxLen);
      node.maxLen = 1 + Math.max(...childMaxLens);
    } else {
      node.maxLen = 1; // Leaf node
    }
  }
}

function calculateMasterParent(blocks) {
  for (const block of blocks) {
    block.masterParent = null;
    const parents = block.parents;
    if (parents.length === 0) continue;
    let masterParent = parents[0];

    for (const parent of parents) {
      if (doBlocksOverlapText(block, parent)) {
        block.parentsOverlappingTexts.push(parent);
      }

      if (doBlocksOverlapText(block, parent)) {
        parent.hasTextOverlapingChildren = true;
        block.isOverlapingParentText = true;
        if (doBlocksOverlapText(block, masterParent)) {
          masterParent =
            parent.maxLen < masterParent.maxLen ? parent : masterParent;
        } else masterParent = parent;
      }
    }
    block.masterParent = masterParent;
  }
}
