import React, { useContext, useEffect, useRef, useState } from "react";
import { TimeBlocksHeader } from "./TimeBlocksHeader";
import { Column } from "./Column";
import { v4 as uuid } from "uuid";
import { getUpdatedBlockPositions } from "../Functions/BlockPosition/BlockPosition";
import { GetBlockSize } from "../Functions/BlockPosition/BlockAdjustment";
import {
  dateToNumber,
  daysFromNow,
  getDatesBySetting,
  getWeekDates,
} from "../Functions/BlockPosition/ColumnDate";
import {
  ActivityContext,
  DateContext,
  DateCountContext,
  InitialDataLoadedContext,
  ModalContext,
} from "../App";
import { useDebounce } from "use-debounce";
import DatePicker, { ReactDatePicker } from "react-datepicker";
import "./TimeBlocks.css";
import axiosInstance from "../httpInterceptor";
import ConfirmRecurringOptionModal from "./EditRecurringOptionModal";

const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const defaultBlockColor = "#4A88A4"; //blue
// const defaultBlockColor = "#F87171"; //red

const defaultAdjustment = {
  dragging: false,
  moving: false,
  resizing: false,
  resizingSide: null,
  blockId: null,
  columnId: null,

  initialStartSegment: null,
  initialEndSegment: null,

  initialMouseSegment: null,
  initialStartOffset: null,
  initialEndOffset: null,
};

export function TimeBlocks() {
  const [columns, setColumns] = useState([]);
  const [activeBlock, _setActiveBlock] = useState(null);
  const [blockAdjustments, _setBlockAdjustments] = useState(defaultAdjustment);
  const [loading, setLoading] = useState(false);
  const blockAdjustmentsRef = React.useRef(blockAdjustments);
  const activeBlockRef = React.useRef(activeBlock);
  const [activities, setActivities] = useContext(ActivityContext);
  const [date, setDate] = useContext(DateContext);
  const [colorChangeValues, setColorChangeValues] = useState(null);
  const [debouncedColorChangeValues] = useDebounce(colorChangeValues, 200);
  const [titleChange, setTitleChange] = useState(null);
  const [debouncedTitleChange] = useDebounce(titleChange, 500);
  const scrollRef = useRef(null);
  const [refCurrent, setRefCurrent] = useState(null);
  const [preChangeBlock, setPreChangeBlock] = useState(null);
  const [dateCount, setDateCount] = useContext(DateCountContext);
  const [confirmRecurringModalOpen, setConfirmRecurringModalOpen] =
    useState(false);

  const setBlockAdjustments = (data) => {
    blockAdjustmentsRef.current = data;
    _setBlockAdjustments(data);
  };
  const setActiveBlock = (data) => {
    activeBlockRef.current = data;
    _setActiveBlock(data);
  };

  const [columnBlockPairs, setColumnBlockPairs] = useState({});

  const [initialDataLoaded, setInitialDataLoaded] = useContext(
    InitialDataLoadedContext
  );

  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  const [calWidthRefCurrent, setCalWidthRefCurrent] = useState(null);
  const calWidthRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [nColumns, setNColumns] = useState(7);
  const [columnWidth, setColumnWidth] = useState(190);
  const [loadingWidth, setloadingWidth] = useState(true);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [initialColumnCountLoaded, setInitialColumnCountLoaded] =
    useState(false);
  const [updatingBlockIds, _setUpdatingBlockIds] = useState({});
  const [updatingRecurranceIds, _setUpdatingRecurranceIds] = useState({});
  const updatingBlockIdsRef = React.useRef(updatingBlockIds);
  const updatingRecurranceIdsRef = React.useRef(updatingRecurranceIds);
  const setUpdatingBlockIds = (data) => {
    updatingBlockIdsRef.current = data;
    _setUpdatingBlockIds(data);
  };
  const setUpdatingRecurranceIds = (data) => {
    updatingRecurranceIdsRef.current = data;
    _setUpdatingRecurranceIds(data);
  };

  const [recurranceData, setRecurranceData] = useState(null);

  useEffect(() => {
    if (calWidthRefCurrent) {
      const handleWindowResize = () => {
        setWidth(calWidthRefCurrent.offsetWidth);
      };
      handleWindowResize();
      window.addEventListener("resize", handleWindowResize);

      return () => {
        window.removeEventListener("resize", handleWindowResize);
      };
    }
  }, [calWidthRefCurrent]);

  useEffect(() => {
    if (width === 0) {
      return;
    }
    const translatedWidth = width - 38; // time width
    const columnCount = getColumnCount(translatedWidth);
    setDateCount(columnCount);
  }, [width]);

  useEffect(() => {
    if (dateCount == null) return;
    const translatedWidth = width - 38; // time width
    const columnWidth = (translatedWidth - (dateCount - 1) * 8) / dateCount;
    if (initialColumnCountLoaded && nColumns === dateCount) {
      setColumns((columns) => {
        return columns.map((column) => {
          return {
            ...column,
            blocks: getUpdatedBlockPositions(column.blocks, columnWidth - 10),
          };
        });
      });
      return;
    }
    setloadingWidth(true);
    if (width === 0) {
      return;
    }

    setLoading(true);
    setNColumns(dateCount);
    setColumnWidth(columnWidth);
    setInitialColumnCountLoaded(true);
    setColumns((columns) => {
      return columns.map((column) => {
        return {
          ...column,
          blocks: getUpdatedBlockPositions(column.blocks, columnWidth - 10),
        };
      });
    });
    setloadingWidth(false);
  }, [dateCount, width]);

  const getColumnCount = (width) => {
    if (width < 500) return 1;
    if (width < 1000) return 3;
    return 7;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 60000); // 60000 milliseconds = 1 minute

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setRefCurrent(scrollRef.current);
    setCalWidthRefCurrent(calWidthRef.current);
  });
  useEffect(() => {
    if (refCurrent && scrollRef.current) {
      scrollRef.current.scrollIntoView();
      setHasScrolled(true);
    }
  }, [refCurrent]);

  useEffect(() => {
    if (!initialDataLoaded || !initialColumnCountLoaded) return;
    setLoading(true);
    getColumns();
  }, [date, initialDataLoaded, nColumns, initialColumnCountLoaded]);

  const getColumns = async () => {
    let dateSetting = "week";
    if (nColumns == 7) dateSetting = "week";
    if (nColumns == 3) dateSetting = "3d";
    if (nColumns == 1) dateSetting = "day";
    const weekDates = getDatesBySetting(date, dateSetting);

    await axiosInstance
      .post("/columns/dates", {
        startDate: weekDates[0],
        endDate: weekDates.slice(-1)[0],
      })
      .then((response) => {
        const DateColumns = [];
        for (const dateObj of response.data.columns) {
          const columnDate = new Date(dateObj.date);
          let blocks = dateObj.blocks ?? [];
          blocks = blocks?.map((b) => insertActivity(b, b.activityId));
          blocks = getUpdatedBlockPositions(blocks, columnWidth - 10);
          DateColumns.push({
            id: dateObj.id,
            blocks,
            dayOfWeek: weekdays[columnDate.getDay()],
            date: columnDate.getDate(),
            isToday: dateToNumber(columnDate) === dateToNumber(new Date()),
            fullDate: columnDate,
          });
        }

        setColumns(() => {
          return DateColumns;
        });
        setLoading(() => false);
      });
  };

  const insertActivity = (block, activityId) => {
    const activity = activities.find((a) => a.id == activityId);
    if (activity === null || activity === undefined || activityId == null)
      return {
        ...block,
        color: block.hasCustomColor ? block.color : defaultBlockColor,
      };

    const updatedBlock = { ...block };
    updatedBlock.color = updatedBlock.hasCustomColor
      ? updatedBlock.color
      : activity.color;
    if (!block.hasCustomTitle) updatedBlock.title = activity.title;

    return updatedBlock;
  };

  const handleTitleChange = (
    columnId,
    updatedBlock,
    value,
    includedBlocksOption
  ) => {
    setTitleChange({
      blockId: updatedBlock.id,
      title: value,
      columnId,
      includedBlocksOption,
    });
    setColumns((columns) => {
      return columns.map((column) =>
        column.id !== columnId
          ? column
          : {
              ...column,
              blocks: column.blocks.map((block) =>
                block.id !== updatedBlock.id
                  ? block
                  : {
                      ...block,
                      title: value,
                      hasCustomTitle: true,
                    }
              ),
            }
      );
    });
  };
  useEffect(() => {
    if (debouncedTitleChange === null) return;
    const column = columns.find(
      (col) => col.id === debouncedTitleChange.columnId
    );
    const block = column.blocks.find(
      (b) => b.id === debouncedTitleChange.blockId
    );
    if (
      block.selectedRadioOption === "future" ||
      block.selectedRadioOption === "all"
    ) {
      const fromDate = column.fullDate;
      setRecurranceLoadingState(block.recurranceId, true);
      patchRecurranceTitle(block, debouncedTitleChange.title, fromDate).then(
        (response) => {
          const responseRecurrance = { ...response.data };
          setRecurranceLoadingState(block.recurranceId, false);
          setColumns((columns) => {
            return columns.map((col) => {
              return {
                ...col,

                ...((block.selectedRadioOption === "all" ||
                  col.fullDate - fromDate >= 0) && {
                  blocks: col.blocks.map((b) =>
                    b.isRecurrance && b.recurranceId === block.recurranceId
                      ? {
                          ...b,
                          ...(b.id !== block.id && {
                            title: debouncedTitleChange.title,
                          }),
                          recurranceId: responseRecurrance.id,
                        }
                      : b
                  ),
                }),
              };
            });
          });
        }
      );
      return;
    }

    patchTitle(block, debouncedTitleChange.title, column.fullDate).then(
      (response) => {
        const responseBlock = { ...response.data };
        const activity = activities.find(
          (a) => a.id === responseBlock.activityId
        );
        responseBlock.color = responseBlock.hasCustomColor
          ? responseBlock.color
          : activity == null
          ? defaultBlockColor
          : activity.color;
        responseBlock.title = responseBlock.hasCustomTitle
          ? responseBlock.title
          : activity == null
          ? ""
          : activity.title;
        // updateColumnsWithBlock(column.id, responseBlock, block.id);
      }
    );
  }, [debouncedTitleChange]);

  async function patchRecurranceTitle(block, title, fromDate) {
    return await axiosInstance.patch(
      "/recurrances/" + block.recurranceId + "/title",
      {
        title,
        option: block.selectedRadioOption,
        fromDate,
      }
    );
  }

  async function patchTitle(block, title, date) {
    return axiosInstance.patch("/blocks/" + block.id + "/title", {
      title,
      date,
      isRecurrance: block.isRecurrance,
      recurranceId: block.recurranceId,
    });
  }

  const handleRemoveActivity = (columnId, updatedBlock) => {
    const column = columns.find((col) => col.id === columnId);
    if (
      updatedBlock.selectedRadioOption === "future" ||
      updatedBlock.selectedRadioOption === "all"
    ) {
      const fromDate = column.fullDate;
      setRecurranceLoadingState(updatedBlock.recurranceId, true);
      patchRecurranceActivity(updatedBlock, null, fromDate).then(
        (response, option) => {
          const responseRecurrance = { ...response.data };
          setRecurranceLoadingState(updatedBlock.recurranceId, false);
          setColumns((columns) => {
            return columns.map((column) => {
              return {
                ...column,

                ...((option === "all" || column.fullDate - fromDate >= 0) && {
                  blocks: column.blocks.map((b) => {
                    if (
                      b.isRecurrance &&
                      b.recurranceId === updatedBlock.recurranceId
                    ) {
                      const updatedBlock = {
                        ...b,
                        activityId: null,
                        recurranceId: responseRecurrance.id,
                      };
                      updatedBlock.color = updatedBlock.hasCustomColor
                        ? updatedBlock.color
                        : defaultBlockColor;
                      updatedBlock.title = updatedBlock.hasCustomTitle
                        ? updatedBlock.title
                        : "";
                      return updatedBlock;
                    }
                    return b;
                  }),
                }),
              };
            });
          });
        }
      );
      return;
    }
    patchActivity(updatedBlock, null, column.fullDate).then((response) => {
      const responseBlock = { ...response?.data };
      if (!responseBlock.hasCustomColor) {
        responseBlock.color = defaultBlockColor;
      }
      if (!responseBlock.hasCustomTitle) {
        responseBlock.title = "";
      }
      // updateColumnsWithBlock(columnId, responseBlock, updatedBlock.id);
    });
  };

  const updateColumnsWithBlock = (columnId, updatedBlock, oldBlockId) => {
    setColumns((columns) => {
      return columns.map((column) => {
        if (column.id !== columnId) return column;
        return upsertBlock(column, updatedBlock, oldBlockId);
      });
    });
    setBlockStyling(columnId, columnWidth);
  };

  const upsertBlock = (column, updatedBlock, oldBlockId) => {
    if (
      column.blocks.filter((block) => block.id === updatedBlock.id).length === 0
    ) {
      return {
        ...column,
        blocks: [
          ...column.blocks.filter((b) => b.id !== oldBlockId),
          updatedBlock,
        ],
      };
    } else {
      return {
        ...column,
        blocks: column.blocks.map((block) =>
          block.id !== updatedBlock.id
            ? block
            : {
                ...updatedBlock,
              }
        ),
      };
    }
  };

  async function patchRecurranceActivity(block, activity, fromDate) {
    return await axiosInstance.patch(
      "/recurrances/" + block.recurranceId + "/activity",
      {
        activityId: activity?.id ?? null,
        option: block.selectedRadioOption,
        fromDate,
      }
    );
  }

  async function handleRecurranceActionSuccess(data, option) {
    axiosInstance
      .patch(data.url, { ...data.data, option })
      .then((response) => data.callback(response, option));
  }

  function handleRecurranceActionCancel(data) {
    setColumns((columns) => {
      return columns.map((column) => {
        if (column.id !== data.columnId) return column;
        return {
          ...column,
          blocks: column.blocks.map((b) => {
            if (b.id !== data.blockId) return b;
            return { ...preChangeBlock };
          }),
        };
      });
    });
  }

  async function patchActivity(block, activityId, date) {
    return axiosInstance.patch("/blocks/" + block.id + "/activity", {
      activityId,
      date,
      isRecurrance: block.isRecurrance,
      recurranceId: block.recurranceId,
    });
  }

  const handleColorChange = (columnId, updatedBlock, color) => {
    setColorChangeValues({ columnId, updatedBlock, color });
  };

  const updateColor = (columnId, updatedBlock, color) => {
    setColumns((columns) => {
      return columns.map((column) =>
        column.id !== columnId
          ? column
          : {
              ...column,
              blocks: column.blocks.map((block) =>
                block.id !== updatedBlock.id
                  ? block
                  : {
                      ...block,
                      color,
                      hasCustomColor: true,
                    }
              ),
            }
      );
    });
  };

  useEffect(() => {
    if (debouncedColorChangeValues === null) return;
    const column = columns.find(
      (col) => col.id === debouncedColorChangeValues.columnId
    );
    if (
      debouncedColorChangeValues.updatedBlock.selectedRadioOption ===
        "future" ||
      debouncedColorChangeValues.updatedBlock.selectedRadioOption === "all"
    ) {
      const fromDate = column.fullDate;
      patchRecurranceColor(
        debouncedColorChangeValues.updatedBlock,
        debouncedColorChangeValues.color,
        fromDate
      ).then((response) => {
        const responseRecurrance = { ...response.data };
        setColumns((columns) => {
          return columns.map((column) => {
            return {
              ...column,

              ...((debouncedColorChangeValues.updatedBlock
                .selectedRadioOption === "all" ||
                column.fullDate - fromDate >= 0) && {
                blocks: column.blocks.map((b) =>
                  b.isRecurrance &&
                  b.recurranceId ===
                    debouncedColorChangeValues.updatedBlock.recurranceId
                    ? {
                        ...b,
                        color: debouncedColorChangeValues.color,
                        recurranceId: responseRecurrance.id,
                      }
                    : b
                ),
              }),
            };
          });
        });
      });
      return;
    }

    patchColor(
      debouncedColorChangeValues.updatedBlock,
      debouncedColorChangeValues.color,
      column.fullDate
    ).then((response) => {
      const responseBlock = { ...response.data };
      const activity = activities.find(
        (a) => a.id === responseBlock.activityId
      );
      responseBlock.color = responseBlock.hasCustomColor
        ? responseBlock.color
        : activity == null
        ? defaultBlockColor
        : activity.color;
      responseBlock.title = responseBlock.hasCustomTitle
        ? responseBlock.title
        : activity == null
        ? ""
        : activity.title;
      updateColumnsWithBlock(
        column.id,
        responseBlock,
        debouncedColorChangeValues.updatedBlock.id
      );
    });
  }, [debouncedColorChangeValues]);

  async function patchRecurranceColor(block, color, fromDate) {
    return axiosInstance.patch(
      "/recurrances/" + block.recurranceId + "/color",
      {
        color,
        option: block.selectedRadioOption,
        fromDate,
      }
    );
  }
  async function patchColor(block, color, date) {
    return axiosInstance.patch("/blocks/" + block.id + "/color", {
      color,
      date,
      isRecurrance: block.isRecurrance,
      recurranceId: block.recurranceId,
    });
  }

  const handleActivityDrop = (activityId, blockId, columnId) => {
    const column = columns.find((col) => col.id === columnId);
    const block = column?.blocks.find((b) => b.id === blockId);

    const activity = activities.find((a) => a.id === activityId);
    // if (block.changeAsRecurring) {
    //   const fromDate = column.fullDate;
    //   const url = "/recurrances/" + block.recurranceId + "/activity";
    //   const callback = (response, option) => {
    //     const responseRecurrance = { ...response.data };
    //     setColumns((columns) => {
    //       return columns.map((column) => {
    //         return {
    //           ...column,

    //           ...((option === "all" || column.fullDate - fromDate >= 0) && {
    //             blocks: column.blocks.map((b) => {
    //               if (b.isRecurrance && b.recurranceId === block.recurranceId) {
    //                 const updatedBlock = {
    //                   ...b,
    //                   activityId: activity?.id,
    //                   recurranceId: responseRecurrance.id,
    //                 };
    //                 updatedBlock.color = block.hasCustomColor
    //                   ? block.color
    //                   : activity?.color ?? defaultBlockColor;
    //                 updatedBlock.title = block.hasCustomTitle
    //                   ? block.title
    //                   : activity?.title ?? "";
    //                 return updatedBlock;
    //               }
    //               return b;
    //             }),
    //           }),
    //         };
    //       });
    //     });
    //   };
    //   setRecurranceData({
    //     data: {
    //       activityId: activity?.id,
    //       fromDate: column.fullDate,
    //     },
    //     blockId: block.id,
    //     columnId: block.columnId,
    //     url,
    //     callback,
    //   });
    //   setConfirmRecurringModalOpen(true);
    //   return;
    // }
    if (
      block.selectedRadioOption === "future" ||
      block.selectedRadioOption === "all"
    ) {
      const fromDate = column.fullDate;
      setRecurranceLoadingState(block.recurranceId, true);
      patchRecurranceActivity(block, activity, fromDate).then((response) => {
        const responseRecurrance = { ...response.data };
        setRecurranceLoadingState(block.recurranceId, false);
        setColumns((columns) => {
          return columns.map((column) => {
            return {
              ...column,

              ...((block.selectedRadioOption === "all" ||
                column.fullDate - fromDate >= 0) && {
                blocks: column.blocks.map((b) => {
                  if (b.isRecurrance && b.recurranceId === block.recurranceId) {
                    const updatedBlock = {
                      ...b,
                      activityId: activity?.id,
                      recurranceId: responseRecurrance.id,
                    };
                    updatedBlock.color = block.hasCustomColor
                      ? block.color
                      : activity?.color ?? defaultBlockColor;
                    updatedBlock.title = block.hasCustomTitle
                      ? block.title
                      : activity?.title ?? "";
                    return updatedBlock;
                  }
                  return b;
                }),
              }),
            };
          });
        });
      });
      return;
    }
    patchActivity(block, activityId, column.fullDate).then((response) => {
      const responseBlock = { ...response?.data };
      responseBlock.color = block.hasCustomColor ? block.color : activity.color;
      responseBlock.title = block.hasCustomTitle ? block.title : activity.title;
      updateColumnsWithBlock(columnId, responseBlock, blockId);
    });
  };

  const onEnterSegment = (segment, columnId) => {
    if (
      !blockAdjustments.dragging &&
      !blockAdjustments.resizing &&
      !blockAdjustments.moving
    )
      return;

    let currentColumn = columns.find((column) => column.id === columnId);

    //moving to other column
    if (blockAdjustments.moving && columnId !== blockAdjustments.columnId) {
      const oldColumn = columns.find(
        (column) => column.id === blockAdjustments.columnId
      );

      const block = oldColumn.blocks.find(
        (b) => b.id === blockAdjustments.blockId
      );

      const updatedBlocksInOld = oldColumn.blocks.filter(
        (b) => b.id !== block.id
      );

      const updatedBlocksInCurrent = [...currentColumn.blocks, block];

      const updatedValus = GetBlockSize(block, blockAdjustments, segment);
      setActiveBlock({
        ...updatedValus.block,
        columnId: currentColumn.id,
      });
      updatedValus.updatedBlockAdjustments.columnId = currentColumn.id;
      setBlockAdjustments(updatedValus.updatedBlockAdjustments);
      setColumns((columns) => {
        return columns.map((column) => {
          if (column.id === oldColumn.id) {
            return { ...column, blocks: updatedBlocksInOld };
          }
          if (column.id === currentColumn.id) {
            return { ...column, blocks: updatedBlocksInCurrent };
          }

          return {
            ...column,
            blocks: column.blocks.filter((b) => b.id !== block.id), // if dragging fast, state might not be updated correctly without this
          };
        });
      });
      return;
    }

    if (blockAdjustments.resizing || blockAdjustments.dragging) {
      //fix which allowing drag over other columns without changing column
      // only change is looking at the blockAdjustments.columnId instead of segment columnId
      currentColumn = columns.find(
        (column) => column.id === blockAdjustments.columnId
      );
      const block = currentColumn.blocks.find(
        (b) => b.id === blockAdjustments.blockId
      );
      const currentColumnId = blockAdjustments.columnId;
      const updatedValus = GetBlockSize(block, blockAdjustments, segment);

      const updatedBlocks = currentColumn.blocks.map((b) =>
        b.id !== block.id ? b : updatedValus.block
      );

      setActiveBlock(updatedValus.block);

      setBlockAdjustments(updatedValus.updatedBlockAdjustments);
      setColumns((columns) => {
        return columns.map((column) =>
          column.id !== currentColumnId
            ? column
            : {
                ...column,
                blocks: updatedBlocks,
              }
        );
      });
      return;
    }

    if (columnId !== blockAdjustments.columnId) return;

    const block = currentColumn.blocks.find(
      (b) => b.id === blockAdjustments.blockId
    );
    const updatedValus = GetBlockSize(block, blockAdjustments, segment);

    const updatedBlocks = currentColumn.blocks.map((b) =>
      b.id !== block.id ? b : updatedValus.block
    );

    setActiveBlock(updatedValus.block);

    setBlockAdjustments(updatedValus.updatedBlockAdjustments);
    setColumns((columns) => {
      return columns.map((column) =>
        column.id !== columnId
          ? column
          : {
              ...column,
              blocks: updatedBlocks,
            }
      );
    });
  };

  const onStartDrag = (segment, columnId) => {
    const newBlock = {
      start: segment,
      end: segment,
      id: uuid(),
      createdDate: Date.now(),
      color: defaultBlockColor,
      activityId: null,
      title: "",
    };

    setActiveBlock(newBlock);

    setColumns((columns) => {
      return columns.map((column) =>
        column.id !== columnId
          ? column
          : {
              ...column,
              blocks: [...column.blocks, newBlock],
            }
      );
    });

    setBlockAdjustments({
      ...blockAdjustments,
      dragging: true,
      blockId: newBlock.id,
      columnId: columnId,
    });

    document.addEventListener("mouseup", onEndDrag);
  };

  const onEndMove = () => {
    document.removeEventListener("mouseup", onEndMove);
    const columnId = blockAdjustmentsRef.current.columnId;
    const initialStart = blockAdjustmentsRef.current.initialStartSegment;
    const oldColumnId = blockAdjustmentsRef.current.oldColumnId;
    const block = { ...activeBlockRef.current };

    setBlockAdjustments({
      ...blockAdjustments,
      moving: false,
      initialStartSegment: null,
      initialEndSegment: null,
      initialMouseSegment: null,
      blockId: null,
      columnId: null,
      oldColumnId: null,
    });
    setActiveBlock(null);
    if (initialStart == block.start && oldColumnId == block.columnId) return;

    setBlockStyling(columnId, columnWidth);
    const column = columns.find((col) => col.id === columnId);
    const oldColumn = columns.find((col) => col.id === oldColumnId);
    if (
      block.selectedRadioOption === "future" ||
      block.selectedRadioOption === "all"
    ) {
      setRecurranceLoadingState(block.recurranceId, true);
      const fromDate = column.fullDate;
      patchRecurranceMove(block, fromDate).then((response) => {
        const responseRecurrance = { ...response.data };
        setRecurranceLoadingState(block.recurranceId, false);
        setColumns((columns) => {
          return columns.map((column) => {
            return {
              ...column,

              ...((block.selectedRadioOption === "all" ||
                column.fullDate - fromDate >= 0) && {
                blocks: column.blocks.map((b) =>
                  b.isRecurrance && b.recurranceId === block.recurranceId
                    ? {
                        ...b,
                        start: block.start,
                        end: block.end,
                        recurranceId: responseRecurrance.id,
                      }
                    : b
                ),
              }),
            };
          });
        });
      });
      return;
    }
    patchBlockMove(block, column, oldColumn);
  };

  function setLoadingState(blockId, loading) {
    setUpdatingBlockIds((current) => {
      const updatedObj = { ...current };
      updatedObj[blockId] = loading;
      return updatedObj;
    });
  }
  function setRecurranceLoadingState(recurranceId, loading) {
    setUpdatingRecurranceIds((current) => {
      const updatedObj = { ...current };
      updatedObj[recurranceId] = loading;
      return updatedObj;
    });
  }

  async function patchRecurranceMove(block, fromDate) {
    return await axiosInstance.patch(
      "/recurrances/move/" + block.recurranceId,
      {
        start: block.start,
        end: block.end,
        option: block.selectedRadioOption,
        fromDate,
      }
    );
  }

  async function patchBlockMove(block, column, oldColumn) {
    await axiosInstance
      .patch("/blocks/move/" + block.id, {
        start: block.start,
        end: block.end,
        columnId: block.columnId,
        date: column.fullDate,
        isRecurrance: block.isRecurrance ?? false,
        recurranceId: block.recurranceId,
        oldDate: oldColumn.fullDate,
      })
      .then((response) => {
        const responseBlock = { ...response.data };
        const activity = activities.find(
          (a) => a.id === responseBlock.activityId
        );
        responseBlock.color = block.hasCustomColor
          ? block.color
          : activity == null
          ? defaultBlockColor
          : activity.color;
        responseBlock.title = block.hasCustomTitle
          ? block.title
          : activity == null
          ? ""
          : activity.title;
        // updateColumnsWithBlock(column.id, responseBlock, block.id);
        // setColumns((columns) => {
        //   return columns.map((c) => {
        //     if (c.id !== column.id) return c;
        //     else {
        //       const updatedBlocks = c.blocks.map((b) =>
        //         b.id !== block.id ? b : { ...b, id: response.data.id }
        //       );
        //       return {
        //         ...c,
        //         id: response.data.columnId,
        //         blocks: updatedBlocks,
        //       };
        //     }
        //   });
        // });
      });
  }

  const onStartMove = (columnId, block) => {
    setBlockAdjustments({
      ...blockAdjustments,
      moving: true,
      blockId: block.id,
      columnId: columnId,
      initialStartSegment: block.start,
      initialEndSegment: block.end,
      oldColumnId: columnId,
    });
    setPreChangeBlock({ ...block });

    document.addEventListener("mouseup", onEndMove);
  };

  const onStartResize = (columnId, block, resizingSide) => {
    setBlockAdjustments({
      ...blockAdjustments,
      resizing: true,
      blockId: block.id,
      columnId,
      resizingSide,
    });

    document.addEventListener("mouseup", onEndResize);
  };

  const setBlockStyling = (columnId, columnWidth = 190) => {
    setColumns((columns) => {
      return columns.map((column) => {
        if (column.id !== columnId) return column;
        return {
          ...column,
          blocks: getUpdatedBlockPositions(column.blocks, columnWidth - 10),
        };
      });
    });
  };

  const onEndDrag = () => {
    document.removeEventListener("mouseup", onEndDrag);
    const columnId = blockAdjustmentsRef.current.columnId;
    const block = { ...activeBlockRef.current };

    setBlockAdjustments({
      ...blockAdjustments,
      dragging: false,
      resizing: false,
      blockId: null,
      columnId: null,
    });
    setBlockStyling(columnId, columnWidth);
    setActiveBlock(null);
    const column = columns.find((col) => col.id === columnId);

    postBlock(block, column);
  };

  async function postBlock(block, column) {
    await axiosInstance
      .post("/blocks", {
        ...block,
        columnId: column.id,
        date: column.fullDate,
      })
      .then((response) => {
        setColumns((columns) => {
          return columns.map((c) => {
            if (c.id !== column.id) return c;
            else {
              const updatedBlocks = c.blocks.map((b) =>
                b.id !== block.id ? b : { ...b, id: response.data.id }
              );
              return {
                ...c,
                id: response.data.columnId,
                blocks: updatedBlocks,
              };
            }
          });
        });
      });
  }

  const onEndResize = () => {
    document.removeEventListener("mouseup", onEndResize);
    const columnId = blockAdjustmentsRef.current.columnId;
    const block = { ...activeBlockRef.current };

    setBlockAdjustments({
      ...blockAdjustments,
      dragging: false,
      resizing: false,
      blockId: null,
      columnId: null,
    });
    setActiveBlock(null);
    setBlockStyling(columnId, columnWidth);
    const column = columns.find((col) => col.id === columnId);

    patchBlockResize(block, column);
  };

  async function patchBlockResize(block, column) {
    const fromDate = column.fullDate;
    if (
      block.selectedRadioOption === "future" ||
      block.selectedRadioOption === "all"
    ) {
      setRecurranceLoadingState(block.recurranceId, true);
      patchRecurranceResize(block, fromDate).then((response) => {
        const responseRecurrance = { ...response.data };
        setRecurranceLoadingState(block.recurranceId, false);
        setColumns((columns) => {
          return columns.map((column) => {
            return {
              ...column,
              //block.selectedRadioOption might have changed when the response is back, should save the option in const instead
              ...((block.selectedRadioOption === "all" ||
                column.fullDate - fromDate >= 0) && {
                blocks: column.blocks.map((b) =>
                  b.isRecurrance && b.recurranceId === block.recurranceId
                    ? {
                        ...b,
                        start: block.start,
                        end: block.end,
                        recurranceId: responseRecurrance.id,
                      }
                    : b
                ),
              }),
            };
          });
        });
      });
      return;
    }

    return await axiosInstance
      .patch("/blocks/resize/" + block.id, {
        start: block.start,
        end: block.end,
        date: column.fullDate,
        isRecurrance: block.isRecurrance,
        recurranceId: block.recurranceId,
      })
      .then((response) => {
        const responseBlock = { ...response.data };
        const activity = activities.find(
          (a) => a.id === responseBlock.activityId
        );
        responseBlock.color = block.hasCustomColor
          ? block.color
          : activity == null
          ? defaultBlockColor
          : activity.color;
        responseBlock.title = block.hasCustomTitle
          ? block.title
          : activity == null
          ? ""
          : activity.title;
        // updateColumnsWithBlock(column.id, responseBlock, block.id);
      });
  }

  async function patchRecurranceResize(block, fromDate) {
    return await axiosInstance.patch(
      "/recurrances/resize/" + block.recurranceId,
      {
        start: block.start,
        end: block.end,
        option: block.selectedRadioOption,
        fromDate,
      }
    );
  }

  const onDelete = (columnId, block) => {
    setColumns((columns) => {
      return columns.map((column) =>
        column.id !== columnId
          ? column
          : {
              ...column,
              blocks: column.blocks.filter((b) => b.id !== block.id),
            }
      );
    });
    setBlockStyling(columnId, columnWidth);
    const column = columns.find((c) => c.id === columnId);
    deleteblock(block, column.fullDate);
  };

  async function deleteblock(block, date) {
    await axiosInstance.post("/blocks/delete/" + block.id, {
      date,
      isRecurrance: block.isRecurrance,
      recurranceId: block.recurranceId,
    });
    // await axiosInstance.delete("/blocks/" + blockId);
  }

  const handleRecurringChange = (block, recurring, pattern) => {
    if (recurring) {
      postRecurrance(block.id, pattern).then(() => {
        getColumns();
      });
    } else {
      const column = columns.find((c) => c.id === block.columnId);
      const fromDate = column?.fullDate;
      const url = "/recurrances/delete/" + block.recurranceId;
      const callback = (response) => {
        getColumns();
      };
      setRecurranceData({
        data: { fromDate: column?.fullDate },
        blockId: block.id,
        columnId: block.columnId,
        url,
        callback,
      });
      setConfirmRecurringModalOpen(true);
      return;

      // deleteRecurrance(block, fromDate).then((response) => {
      //   getColumns();
      // });
    }
  };
  async function postRecurrance(blockId, pattern) {
    await axiosInstance.post("/recurrances/" + blockId, { pattern });
  }
  async function deleteRecurrance(block, fromDate) {
    await axiosInstance.post("/recurrances/delete/" + block.recurranceId, {
      option: block.selectedRadioOption,
      fromDate,
    });
  }
  async function patchRecurrancePattern(recurranceId, pattern) {
    await axiosInstance.patch("/recurrances/pattern/" + recurranceId, {
      pattern,
    });
  }
  const handlePatternChange = (block, pattern) => {
    patchRecurrancePattern(block.recurranceId, pattern).then(() => {
      getColumns();
    });
  };

  const handleChangeAsRecurringChange = (block, value) => {
    setColumns((columns) => {
      return columns.map((column) =>
        column.id !== block.columnId
          ? column
          : {
              ...column,
              blocks: column.blocks.map((b) =>
                b.id !== block.id ? b : { ...b, selectedRadioOption: value }
              ),
            }
      );
    });
    // setColumns((columns) => {
    //   return columns.map((column) =>
    //     column.id !== block.columnId
    //       ? column
    //       : {
    //           ...column,
    //           blocks: column.blocks.map((b) =>
    //             b.id !== block.id ? b : { ...b, changeAsRecurring: value }
    //           ),
    //         }
    //   );
    // });
  };

  const renderColumnDates = () => {
    return columns.map((column) => (
      <ColumnDate key={column.id} column={column} />
    ));
  };

  const renderColumns = () => {
    return columns.map((column) => (
      <Column
        key={column.id}
        column={column}
        id={column.id}
        blocks={column.blocks}
        adjustingBlock={blockAdjustments.blockId}
        showBlocks={!loadingWidth && hasScrolled}
        onEnterSegment={onEnterSegment}
        onStartDrag={onStartDrag}
        onStartMove={onStartMove}
        onStartResize={onStartResize}
        onDelete={onDelete}
        onDrop={handleActivityDrop}
        onRemoveActivity={handleRemoveActivity}
        onTitleChange={handleTitleChange}
        onColorChange={handleColorChange}
        onRecurringChange={handleRecurringChange}
        onPatternChange={handlePatternChange}
        onChangeAsRecurringChange={handleChangeAsRecurringChange}
        updatingBlockIds={updatingBlockIds}
        updatingRecurranceIds={updatingRecurranceIds}
      />
    ));
  };

  const times = Array.from(Array(24).keys()).map((i) => {
    if (i < 10) {
      return <span key={i}>0{i}.00</span>;
    }
    return <span key={i}>{i}.00</span>;
  });

  let gridTemplateColumnString = "";
  for (let index = 0; index < nColumns; index++) {
    gridTemplateColumnString = gridTemplateColumnString + "1fr ";
  }

  const renderTimeLine = (time) => {
    const fullHour = time.getHours() + time.getMinutes() / 60; //ex 7.85
    const segmentMultiplier = 4;
    const asClass = "calc(0.75rem * " + fullHour * segmentMultiplier + ")";
    return (
      <div
        className="absolute w-full z-10 pointer-events-none select-none"
        style={{ top: asClass }}
      >
        <div className="bg-primary h-[2px]"></div>
      </div>
    );
  };

  const renderScrollObj = (ref) => {
    const segmentMultiplier = 4;
    const startHour = 6;
    const topValue = "calc(0.75rem * " + startHour * segmentMultiplier + ")";

    return (
      <div
        ref={ref}
        className="absolute w-full pointer-events-none select-none"
        style={{ top: topValue }}
      ></div>
    );
  };

  return (
    <>
      <div className="mx-1 sm:mx-12  max-h-screen" ref={calWidthRef}>
        <TimeBlocksHeader />
        {(loading || !initialDataLoaded) && <div>Loading...</div>}
        {!loadingWidth && !loading && (
          <>
            <div
              className="gap-2 ml-[46px] bg-transparent"
              style={{
                display: "grid",
                gridTemplateColumns: gridTemplateColumnString,
              }}
            >
              {renderColumnDates()}
            </div>

            <div
              className="gap-2 py-2 select-none screen-height overflow-scroll hide-scroll relative "
              style={{
                display: "grid",
                gridTemplateColumns: "min-content " + gridTemplateColumnString,
              }}
              draggable="false"
            >
              {renderTimeLine(currentDateTime)}
              {renderScrollObj(scrollRef)}

              <div className="-mt-3 flex flex-col gap-6 text-light overflow-visible">
                {times}
              </div>
              {renderColumns()}
            </div>
          </>
        )}
      </div>
      <ModalContext.Provider
        value={[confirmRecurringModalOpen, setConfirmRecurringModalOpen]}
      >
        <ConfirmRecurringOptionModal
          data={recurranceData}
          onSuccess={handleRecurranceActionSuccess}
          onCancel={handleRecurranceActionCancel}
        />
      </ModalContext.Provider>
    </>
  );
}

function ColumnDate({ column }) {
  return (
    <div className="w-full h-20">
      <div
        className={[
          column.isToday ? "" : "opacity-40",
          "w-min mx-auto text-5xl text-black dark:text-white",
        ].join(" ")}
      >
        {column.date}
      </div>
      <div
        className={[
          column.isToday ? "" : "opacity-40",
          "w-min mx-auto text-black dark:text-white",
        ].join(" ")}
      >
        {column.dayOfWeek}
      </div>
    </div>
  );
}
