import { useRef, useState } from "react";
import { ContextMenu } from "./ContextMenu";
import { ModalContext } from "../App";
import { SetRecurringPatternModal } from "./SetRecurringPatternModal";

export function Block({
  block,
  isSolid,
  triggerEvents,
  onMove,
  onResize,
  onDeleteBlock,
  onDrop,
  onEdit,
  onTitleChange,
  onRemoveActivity,
  onColorChange,
  onRecurringChange,
  onPatternChange,
  onChangeAsRecurringChange,
  isUpdating,
}) {
  const [recurringPatternModalOpen, setRecurringPatternModalOpen] =
    useState(false);

  const [contextMenuProps, setContextMenuProps] = useState({
    x: 0,
    y: 0,
    open: false,
  });
  let blockTop = block.start;
  let blockHeight = block.end - block.start + 1;

  const divRef = useRef(null);

  //this should not be neccesary anymore
  if (block.end < block.start) {
    blockTop = block.end;
    blockHeight = block.start - block.end + 1;
  }

  const top = `${blockTop * 12}px`;
  const height = `${blockHeight * 12}px`;

  const times = Array.from(Array(24).keys()).map((i) => {
    if (i < 10) {
      return <span key={i}>0{i}.00</span>;
    }
    return <span key={i}>{i}.00</span>;
  });

  const startHour =
    Math.floor(blockTop / 4) < 10
      ? `0${Math.floor(blockTop / 4)}`
      : `${Math.floor(blockTop / 4)}`;
  const startMinute = `${(blockTop % 4) * 15}`.padEnd(2, "0");
  const startTime = `${startHour}:${startMinute}`;

  const endHour =
    Math.floor((blockTop + blockHeight) / 4) < 10
      ? `0${Math.floor((blockTop + blockHeight) / 4)}`
      : `${Math.floor((blockTop + blockHeight) / 4)}`;
  const endMinute = `${((blockTop + blockHeight) % 4) * 15}`.padEnd(2, "0");
  const endTime = `${endHour}:${endMinute}`;

  const zIndex = !isSolid ? "1000" : block.zIndex;

  const blockStyles = {
    top,
    height,
    width: block?.width ?? "90%",
    left: block?.left ?? "0",
    zIndex: contextMenuProps.open ? "999" : zIndex,
    backgroundColor: block?.color,
  };

  const handleDropActivity = (event) => {
    if (isUpdating) return;
    const activityId = event.dataTransfer.getData("activityId");
    onDrop(activityId, block.id);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const [isRecurring, setIsRecurring] = useState(block.isRecurrance);
  const handleRecurringChange = (recurring) => {
    if (recurring == false) {
      onRecurringChange(block, false);
      setIsRecurring(false);
    } else {
      setRecurringPatternModalOpen(true);
    }
  };
  const handleRecurringPatternSuccess = (pattern) => {
    onRecurringChange(block, true, pattern);
  };
  const handleRecurringPatternCancel = () => {
    setIsRecurring(block.isRecurrance);
  };

  const handleSetOpen = (open) => {
    setContextMenuProps((props) => ({ ...props, open }));
  };
  const handleRightClick = (event) => {
    event.preventDefault();
    if (isUpdating) return;
    const rect = divRef.current.getBoundingClientRect();

    const isLeft = event.clientX < window?.innerWidth / 2;
    let x = event.pageX - rect.left - window.scrollX;
    const contextMenuWidth = 222;
    if (!isLeft) {
      x = event.pageX - rect.left - contextMenuWidth - window.scrollX;
    }

    const y = event.pageY - rect.top - window.scrollY;
    setContextMenuProps({ x, y, open: true });
  };

  let size;
  if (block.end - block.start > 2) size = "text-[14px]";
  if (block.end - block.start === 2) size = "text-[12px]";
  if (block.end - block.start === 1) size = "text-[12px]";
  if (block.end - block.start === 0) size = "text-[12px] leading-[.6rem]";
  return (
    <div
      ref={divRef}
      className={[
        isSolid && !triggerEvents ? "" : "pointer-events-none",
        "absolute border border-black dark:border-white rounded-md",
      ].join(" ")}
      style={blockStyles}
      onMouseDown={(e) => {
        e.stopPropagation();
        if (isUpdating) return;
        if (e.button === 0) onMove(block); //left mouse
        if (e.button === 1) onDeleteBlock(block); //middle mouse
      }}
      draggable="false"
      onDrop={handleDropActivity}
      onDragOver={handleDragOver}
      onContextMenu={handleRightClick}
    >
      <div
        className={[
          isSolid ? "" : "hidden",
          block.end - block.start > 2 ? "h-2" : "h-1",
          "absolute top-0 left-0 right-0 cursor-ns-resize pointer-events-auto",
        ].join(" ")}
        onMouseDown={(e) => {
          e.stopPropagation();
          if (isUpdating) return;
          if (e.button === 0) onResize(block, "top");
        }}
      ></div>
      <div
        className={[
          "absolute w-full h-full overflow-hidden px-1 flex pointer-events-none select-none",
          size,
          block.end - block.start < 3 ? "gap-1" : "flex-col",
        ].join(" ")}
      >
        <span> {block.title === "" ? "(No title)" : block.title}</span>
        <span>
          {startTime} - {endTime}
        </span>
      </div>
      <div
        className={[
          isSolid ? "" : "hidden",
          block.end - block.start > 2 ? "h-2" : "h-1",
          "absolute bottom-0 left-0 right-0 cursor-ns-resize pointer-events-auto",
        ].join(" ")}
        onMouseDown={(e) => {
          e.stopPropagation();
          if (isUpdating) return;
          if (e.button === 0) onResize(block, "bottom");
          if (e.button === 2) handleRightClick(e);
        }}
      ></div>

      <ContextMenu
        block={block}
        top={contextMenuProps.y}
        left={contextMenuProps.x}
        open={contextMenuProps.open}
        setOpen={handleSetOpen}
        onRemove={() => onDeleteBlock(block)}
        onEdit={() => onEdit(block)}
        onRemoveActivity={() => onRemoveActivity(block)}
        onTitleChange={(title) => onTitleChange(block, title)}
        onColorChange={(color) => onColorChange(block, color)}
        onRecurringChange={handleRecurringChange}
        onChangeAsRecurringChange={(value) =>
          onChangeAsRecurringChange(block, value)
        }
        onPatternChange={(pattern) => onPatternChange(block, pattern)}
      ></ContextMenu>

      <ModalContext.Provider
        value={[recurringPatternModalOpen, setRecurringPatternModalOpen]}
      >
        <div onMouseDown={(e) => e.stopPropagation()}>
          <SetRecurringPatternModal
            onPatternChange={onPatternChange}
            onSetSuccess={handleRecurringPatternSuccess}
            onCancel={handleRecurringPatternCancel}
          />
        </div>
      </ModalContext.Provider>
    </div>
  );
}
