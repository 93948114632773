import React, { useEffect, useState } from "react";
import googleIcon from "../assets/icons/google-logo.svg";
import { signInWithGoogle } from "../firebase";
import { HeaderNavigationMenu } from "./HeaderNavigationMenu";
import { useNavigate } from "react-router-dom";

export function Login() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = (e) => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSignInWithGoogle = () => {
    signInWithGoogle().then(() => {
      navigate("/timeBlocks");
    });
  };

  return (
    <div>
      {windowWidth < 600 && (
        <div className="flex py-2">
          <div className="ml-auto">
            <HeaderNavigationMenu />
          </div>
        </div>
      )}

      <div className="container mx-auto pt-16">
        <main className="mx-auto max-w-5xl flex justify-center lg:justify-start lg:py-5 lg:bg-secondaryLight lg:flex lg:items-center lg:content-center lg:shadow-xl text-black dark:text-white dark:lg:bg-lightDark">
          <div className="lg:w-1/2 h-full">
            <h1 className="text-4xl w-max mx-auto">Welcome back!</h1>
            <p className="w-max mx-auto">Please enter your details.</p>
            <GoogleButton login={handleSignInWithGoogle} />
          </div>
          <img className="hidden w-1/2 lg:block" alt="" />
        </main>
      </div>
    </div>
  );
}
function GoogleButton({ login }) {
  return (
    <div className="max-w-7xl mx-auto py-10 sm:py-20">
      <div className="mx-auto w-max">
        <button
          className="border rounded-md px-5 py-3 flex items-center border-black dark:border-white"
          onClick={login}
        >
          <i>
            <img className="w-7" src={googleIcon} alt="" />
          </i>
          <p>Sign in with Google</p>
        </button>
      </div>
    </div>
  );
}
