import { useContext, useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { ActivityContext, UserContext } from "../App";
import { ActivityItem } from "./ActivityItem";
import arrowDown from "../assets/icons/arrow-down.svg";
import arrowRight from "../assets/icons/arrow-right.svg";
import "./sideMenu.css";
import { getAuth, signOut } from "firebase/auth";

export function SideMenu() {
  const [activities, setActivities] = useContext(ActivityContext);
  const navigate = useNavigate();
  const [user, setUser] = useContext(UserContext);
  const auth = getAuth();
  const url = window.location.href;
  const showActivities =
    url.includes("/timeblocks") || url.includes("/activities");
  const handleLogout = () => {
    signOut(auth);
    navigate("/login");
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = (e) => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (windowWidth < 600) return <></>;

  return (
    <div className="min-h-screen max-h-screen h-screen overflow-y-scroll overflow-x-hidden hide-scroll  w-64 quicksand text-black bg-secondaryLight dark:bg-dark dark:text-white">
      <div className="py-8">
        <div className="w-max mx-auto">
          <Link to="/home">
            <h2 className="hidden dark:block text-3xl quicksand font-semibold logo">
              Blokkly
            </h2>
            <h2 className="dark:hidden text-3xl quicksand font-semibold logo-light">
              Blokkly
            </h2>
          </Link>
        </div>
      </div>

      <Navigation />

      {/* <Line /> */}

      {user && showActivities && (
        <div className="mt-10">
          <div className="mb-1 grid grid-cols-5 items-center">
            <div className="col-span-2">
              <h5 className=" px-3 text-sm w-max mx-auto text-light">
                My Activities
              </h5>
            </div>
            <div className="col-span-3">
              <Line />
            </div>
          </div>
          <ActivitySection title={"Critical & Immediate"}>
            <div className="flex flex-col gap-1">
              {activities.map((activity) =>
                activity.type === "Critical & Immediate" ? (
                  <ActivityItem
                    key={activity._id}
                    id={activity._id}
                    properties={{
                      color: activity.color,
                      title: activity.title,
                    }}
                    showOptions={false}
                  />
                ) : (
                  ""
                )
              )}
            </div>
          </ActivitySection>
          <ActivitySection title={"Personal Projects & Initiatives"}>
            <div className="flex flex-col gap-1">
              {activities.map((activity) =>
                activity.type === "Personal Projects & Initiatives" ? (
                  <ActivityItem
                    key={activity._id}
                    id={activity._id}
                    properties={{
                      color: activity.color,
                      title: activity.title,
                    }}
                    showOptions={false}
                  />
                ) : (
                  ""
                )
              )}
            </div>
          </ActivitySection>
          <ActivitySection title={"Routine Activites"}>
            <div className="flex flex-col gap-1">
              {activities.map((activity) =>
                activity.type === "Routine Activites" ? (
                  <ActivityItem
                    key={activity._id}
                    id={activity._id}
                    properties={{
                      color: activity.color,
                      title: activity.title,
                    }}
                    showOptions={false}
                  />
                ) : (
                  ""
                )
              )}
            </div>
          </ActivitySection>

          <ActivitySection title={"Categories"}>
            <div className="flex flex-col gap-1">
              {activities.map((activity) =>
                activity.type === "Categories" ? (
                  <ActivityItem
                    key={activity._id}
                    id={activity._id}
                    properties={{
                      color: activity.color,
                      title: activity.title,
                    }}
                    showOptions={false}
                  />
                ) : (
                  ""
                )
              )}
            </div>
          </ActivitySection>
        </div>
      )}

      {true && (
        <div className="mt-10 w-min mx-auto">
          <ThemeToggle />
        </div>
      )}

      {user && (
        <div className="mt-10 w-max mx-auto dark:text-light text-sm">
          <button onClick={handleLogout}>Log Out</button>
        </div>
      )}
    </div>
  );
}

function Navigation() {
  return (
    <nav className="flex flex-col">
      <NavLink
        className={({ isActive }) =>
          [
            isActive ? "bg-primary text-white" : "",
            "border-none px-7 w-64 py-4 ",
          ].join(" ")
        }
        to="timeblocks"
      >
        Time blocks
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          [
            isActive ? "bg-primary text-white" : "",
            "border-none px-7 w-64 py-4",
          ].join(" ")
        }
        to="activities"
      >
        Activities
      </NavLink>
    </nav>
  );
}

function Line() {
  return (
    <div className="mx-2">
      <hr className="border-light dark:border-lightDark" />
    </div>
  );
}

function ActivitySection({ children, title }) {
  const [open, setOpen] = useState(false);

  const handleMenuClick = () => {
    setOpen((isOpen) => !isOpen);
  };

  return (
    <div
      role="button"
      className="cursor-pointer select-none"
      onClick={handleMenuClick}
    >
      <div className="p-3">
        <div className="pb-1 flex items-center gap-2">
          <span>
            {open ? (
              <img className="h-1" src={arrowDown} alt="" />
            ) : (
              <img className="w-1" src={arrowRight} alt="" />
            )}
          </span>

          <span className="text-sm">{title}</span>
        </div>
        {open ? <div>{children}</div> : ""}
      </div>
    </div>
  );
}

function ThemeToggle() {
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    const themeIsDarkMode = localStorage.getItem("theme");
    if (themeIsDarkMode === null) {
      setTheme("light");
      localStorage.setItem("theme", "light");
    } else if (themeIsDarkMode === "dark") {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  }, []);

  const handleThemeChange = (themeValue) => {
    setTheme(themeValue);
    if (themeValue === "dark") {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  };

  return (
    <div className="flex flex-col justify-center">
      <input
        type="checkbox"
        name="light-switch"
        className="light-switch sr-only"
        checked={theme === "light"}
        onChange={() => {
          if (theme === "dark") {
            return handleThemeChange("light");
          }
          return handleThemeChange("dark");
        }}
      />
      <label
        onClick={() => {
          if (theme === "dark") {
            return handleThemeChange("light");
          }
          return handleThemeChange("dark");
        }}
        className="relative cursor-pointer p-2 bg-lightDark dark:bg-white rounded-lg"
        htmlFor="light-switch"
      >
        <svg
          className="dark:hidden"
          width="16"
          height="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="fill-white"
            d="M7 0h2v2H7zM12.88 1.637l1.414 1.415-1.415 1.413-1.413-1.414zM14 7h2v2h-2zM12.95 14.433l-1.414-1.413 1.413-1.415 1.415 1.414zM7 14h2v2H7zM2.98 14.364l-1.413-1.415 1.414-1.414 1.414 1.415zM0 7h2v2H0zM3.05 1.706 4.463 3.12 3.05 4.535 1.636 3.12z"
          />
          <path
            className="fill-white"
            d="M8 4C5.8 4 4 5.8 4 8s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4Z"
          />
        </svg>
        <svg
          className="hidden dark:block"
          width="16"
          height="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="fill-slate-600"
            d="M6.2 1C3.2 1.8 1 4.6 1 7.9 1 11.8 4.2 15 8.1 15c3.3 0 6-2.2 6.9-5.2C9.7 11.2 4.8 6.3 6.2 1Z"
          />
          <path
            className="fill-slate-700"
            d="M12.5 5a.625.625 0 0 1-.625-.625 1.252 1.252 0 0 0-1.25-1.25.625.625 0 1 1 0-1.25 1.252 1.252 0 0 0 1.25-1.25.625.625 0 1 1 1.25 0c.001.69.56 1.249 1.25 1.25a.625.625 0 1 1 0 1.25c-.69.001-1.249.56-1.25 1.25A.625.625 0 0 1 12.5 5Z"
          />
        </svg>
        <span className="sr-only">Switch to light / dark version</span>
      </label>
    </div>
  );
}
