import React, { createContext, useContext, useEffect, useState } from "react";
import { ActivityItem } from "./ActivityItem";
import addRound from "../assets/icons/add-round.svg";
import addRoundBlack from "../assets/icons/add-round-black.svg";
import AddActivityModal from "./AddActivityModal";
import AddCategoryModal from "./AddCategoryModal";
import { v4 as uuid } from "uuid";
import { ActivityContext, ModalContext } from "../App";
import EditActivityModal from "./EditActivityModal";
import EditCategoryModal from "./EditCategoroyModal";
import axiosInstance from "../httpInterceptor";
import { HeaderNavigationMenu } from "./HeaderNavigationMenu";

export const CategoryModalContext = createContext(null);

export function Activities() {
  const [open, setOpen] = useState(false);
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const [editActivityModalOpen, setEditActivityModalOpen] = useState(false);
  const [editCategoryModalOpen, setEditCategoryModalOpen] = useState(false);
  const [type, setType] = useState(null);
  const [editActivity, setEditActivity] = useState(null);

  const [activities, setActivities] = useContext(ActivityContext);

  const handleNewActivity = (activity) => {
    PostActivity(activity).then((response) => {
      setActivities((currentActivities) => {
        const newActivity = response.data;
        if (!newActivity.hasCustomColor) {
          const category = currentActivities.find(
            (a) => a._id == activity.categoryId
          );
          newActivity.color = category?.color;
        }

        return [...currentActivities, newActivity];
      });
    });
  };
  const handleActivityEdited = (updatedActivity) => {
    EditActivity(updatedActivity).then((response) => {
      setActivities((currentActivities) => {
        const updatedActivity = response.data;
        if (!updatedActivity.hasCustomColor) {
          const category = currentActivities.find(
            (a) => a._id == updatedActivity.categoryId
          );
          updatedActivity.color = category?.color;
        }

        return currentActivities.map((activity) =>
          activity._id !== updatedActivity._id ? activity : updatedActivity
        );
      });
    });
  };
  const handleCategoryEdited = (updatedCategory) => {
    EditCategory(updatedCategory).then((response) => {
      setActivities((currentActivities) => {
        return currentActivities.map((activity) => {
          if (activity._id === updatedCategory._id) {
            return updatedCategory;
          } else if (activity.categoryId === updatedCategory._id) {
            return { ...activity, color: updatedCategory.color };
          } else {
            return activity;
          }
        });
      });
    });
  };
  const handleNewCategory = (activity) => {
    PostCategory(activity).then((response) => {
      const newActivity = response.data;
      setActivities((currentActivities) => [
        ...currentActivities,
        { ...newActivity, type: "Categories" },
      ]);
    });
  };

  const handleOpenModal = (type) => {
    setType(type);
    setOpen(true);
  };
  const handleOpenCategoryModal = () => {
    setCategoryModalOpen(true);
  };
  const handleDeleteActivity = (activityId) => {
    DeleteActivity(activityId).then((response) => {
      setActivities((currentActivities) =>
        currentActivities.filter((activity) => activity._id !== activityId)
      );
    });
  };
  const handleDeleteCategory = (activityId) => {
    DeleteCategaory(activityId).then((response) => {
      setActivities((currentActivities) =>
        currentActivities.filter((activity) => activity._id !== activityId)
      );
    });
  };
  const handleEditActivity = (activityId) => {
    const activity = activities.find((a) => a._id === activityId);
    setEditActivity(activity);
    setEditActivityModalOpen(true);
  };
  const handleEditCategory = (activityId) => {
    const activity = activities.find((a) => a._id === activityId);
    setEditActivity(activity);
    setEditCategoryModalOpen(true);
  };

  return (
    <div>
      <ModalContext.Provider value={[open, setOpen]}>
        <AddActivityModal
          categories={activities.filter((a) => a.type === "Categories")}
          onCreateActivity={handleNewActivity}
          type={type}
          onTypeChange={setType}
        />
      </ModalContext.Provider>
      <ModalContext.Provider value={[categoryModalOpen, setCategoryModalOpen]}>
        <AddCategoryModal onCreateCategory={handleNewCategory} />
      </ModalContext.Provider>
      <ModalContext.Provider
        value={[editActivityModalOpen, setEditActivityModalOpen]}
      >
        <EditActivityModal
          onEditActivity={handleActivityEdited}
          activity={editActivity}
          categories={activities.filter((a) => a.type === "Categories")}
        />
      </ModalContext.Provider>
      <ModalContext.Provider
        value={[editCategoryModalOpen, setEditCategoryModalOpen]}
      >
        <EditCategoryModal
          onEditCategory={handleCategoryEdited}
          category={editActivity}
        />
      </ModalContext.Provider>

      <ActivitiesHeader />
      <div className="px-12 w-min mx-auto">
        <div className="flex gap-3">
          <ActivityColumn
            title={"Critical & Immediate"}
            activities={activities.filter(
              (activity) => activity.type === "Critical & Immediate"
            )}
            onAdd={() => handleOpenModal("Critical & Immediate")}
            onDelete={handleDeleteActivity}
            onEdit={handleEditActivity}
          />

          <ActivityColumn
            title={"Personal Projects & Initiatives"}
            activities={activities.filter(
              (activity) => activity.type === "Personal Projects & Initiatives"
            )}
            onAdd={() => handleOpenModal("Personal Projects & Initiatives")}
            onDelete={handleDeleteActivity}
            onEdit={handleEditActivity}
          />
          <ActivityColumn
            title={"Routine Activites"}
            activities={activities.filter(
              (activity) => activity.type === "Routine Activites"
            )}
            onAdd={() => handleOpenModal("Routine Activites")}
            onDelete={handleDeleteActivity}
            onEdit={handleEditActivity}
          />
          <CategoryModalContext.Provider
            value={[categoryModalOpen, setCategoryModalOpen]}
          >
            <ActivityColumn
              title={"Categories"}
              activities={activities.filter(
                (activity) => activity.type === "Categories"
              )}
              onAdd={handleOpenCategoryModal}
              onDelete={handleDeleteCategory}
              onEdit={handleEditCategory}
            />
          </CategoryModalContext.Provider>
        </div>
      </div>
    </div>
  );
}

function ActivitiesHeader() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = (e) => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="px-12 py-8 flex">
      <button className="border text-black border-black dark:text-white dark:border-white px-6 rounded-md py-1 text-center">
        Active
      </button>
      {windowWidth < 600 && (
        <div className="">
          <HeaderNavigationMenu />
        </div>
      )}
    </div>
  );
}

function ActivityColumn({ title, activities, onAdd, onDelete, onEdit }) {
  return (
    <div className="w-52 p-3 rounded-md min-h-[600px] bg-secondaryLight text-black dark:text-white dark:bg-dark flex flex-col gap-1">
      <div className="h-14 flex">
        <h3>{title}</h3>
        <button className="ml-auto h-min mt-1" onClick={() => onAdd()}>
          <img className="hidden dark:block" src={addRound} alt="plus icon" />
          <img className="dark:hidden" src={addRoundBlack} alt="plus icon" />
        </button>
      </div>
      {activities.map((activity) => (
        <ActivityItem
          key={activity._id}
          id={activity._id}
          properties={{
            color: activity.color,
            title: activity.title,
          }}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ))}
    </div>
  );
}

async function PostCategory(activity) {
  return await axiosInstance.post("/categorys", activity);
}
async function EditCategory(activity) {
  return await axiosInstance.patch(`/categorys/${activity._id}`, activity);
}
async function DeleteCategaory(categoryId) {
  return await axiosInstance.delete(`/categorys/${categoryId}`);
}

async function PostActivity(activity) {
  return await axiosInstance.post("/activitys", activity);
}
async function EditActivity(activity) {
  return await axiosInstance.patch(`/activitys/${activity._id}`, activity);
}
async function DeleteActivity(activityId) {
  return await axiosInstance.delete(`/activitys/${activityId}`);
}
