import { useContext } from "react";
import { HomeHeader } from "./HomeHeader";
import { UserContext } from "../App";
import { Link } from "react-router-dom";
import exampleBlocks from "../assets/images/ExampleBlocks.png";
import exampleBlocks2 from "../assets/images/ExampleBlocks2.png";

export function Home() {
  const [user, setUser] = useContext(UserContext);

  return (
    <div className="">
      <div className="bg-primary">
        <div className="container mx-auto">
          <HomeHeader />
        </div>
      </div>
      <div>
        <div className="bg-primary">
          <div className="container mx-auto pb-10 quicksand text-center">
            <h1 className="text-3xl sm:text-5xl md:text-8xl w-max mx-auto">
              Blokkly
            </h1>
            <p className="inline-block sm:hidden p-3 text-wrap text text-center">
              Journey towards a more focused and fulfilling day one block at a
              time with Blokkly
            </p>
            <p className="hidden sm:inline-block mt-2 text-wrap text text-center text-xl">
              Journey towards a more focused and fulfilling day
              <br />
              one block at a time with Blokkly
            </p>

            {!user && (
              <div className="mt-2 px-2 py-1 text-lg sm:mt-8 sm:px-6 sm:py-2 w-max mx-auto sm:text-3xl rounded-md bg-[#D78888]">
                <Link to="/login">Sign Up Today</Link>
              </div>
            )}
            {user && (
              <div className="mt-2 px-2 py-1 text-lg sm:mt-8 sm:px-6 sm:py-2 w-max mx-auto sm:text-3xl rounded-md bg-[#D78888]">
                <Link to="/timeblocks">Sign Up Today</Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container mx-auto mt-10 sm:mt-20 md:mt-28">
        <div className="w-max mx-auto">
          <img
            className="w-80 sm:w-[30rem] md:w-[40rem] lg:w-[50rem] xl:w-[60rem] 2xl:w-full"
            src={exampleBlocks2}
            alt=""
          />
        </div>
      </div>

      <div className="container mx-auto p-10 mt-10 sm:mt-20 md:mt-28 quicksand text-black dark:text-white">
        <h2 className="text-xl font-medium sm:text-3xl">
          What is time blocking?
        </h2>
        <p className="mt-2">
          Time blocking is a powerful time management method used to organize
          your day into dedicated segments or 'blocks' of time. Each block is
          allocated to a specific task or group of tasks, rather than
          multitasking or tackling to-dos as they come. This approach encourages
          deep focus, reduces distractions, and increases productivity by
          assigning a set period to each activity, whether it's work-related
          tasks, personal errands, or rest.
          <br />
          <br />
          By visualizing your day in blocks, you can manage your time more
          effectively, ensuring that you dedicate time to all important areas of
          your life without overbooking or underestimating the time tasks
          require. Time blocking helps you create a balanced schedule that
          accommodates work, personal time, and breaks, making your day more
          predictable and controllable.
          <br />
          <br />
          Our app simplifies the time blocking process, allowing you to easily
          plan your day or week. With intuitive design and user-friendly
          features, you can set up your time blocks, adjust them as needed, and
          track your progress towards a more organized and productive lifestyle.
          Start taking control of your time today with Blokkly.
        </p>
      </div>

      <div className="mt-10 sm:mt-20 md:mt-28">
        <div className="bg-primary">
          <div className="container mx-auto pt-10 pb-10 lg:pb-20 quicksand text-center">
            <p className="inline-block sm:hidden p-3 text-wrap text-center">
              Journey towards a more focused and fulfilling day one block at a
              time with Blokkly
            </p>
            <p className="hidden sm:inline-block m-0 mt-2 w-max mx-auto text-center text-2xl md:text-3xl">
              Maximize your productivity by signing in now
              <br />
              start mastering your time today!
            </p>

            {!user && (
              <div className="mt-2 px-2 py-1 text-lg sm:mt-8 sm:px-6 sm:py-3 w-max mx-auto md:text-3xl rounded-md bg-[#D78888]">
                <Link to="/login">Sign Up Today</Link>
              </div>
            )}
            {user && (
              <div className="mt-2 px-2 py-1 text-lg sm:mt-8 sm:px-6 sm:py-3 w-max mx-auto md:text-3xl rounded-md bg-[#D78888]">
                <Link to="/timeblocks">Sign Up Today</Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
