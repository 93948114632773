import React, { createContext, useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Login } from "./appComponents/Login";
import { SideMenu } from "./appComponents/SideMenu";
import { TimeBlocks } from "./appComponents/TimeBlocks";
import { Activities } from "./appComponents/Activities";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import axiosInstance from "./httpInterceptor";
import { Home } from "./home/Home";

export const ActivityContext = createContext(null);
export const InitialDataLoadedContext = createContext(null);
export const DateContext = createContext(null);
export const DateCountContext = createContext(null);
export const UserContext = createContext(null);
export const ModalContext = createContext(null);

const defaultActivities = {
  immiediate: [],
  routine: [],
  personal: [],
  categories: [],
  archived: [],
};

function App() {
  const [activities, setActivities] = useState([]);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [activitiesLoaded, setActivitiesLoaded] = useState(false);
  const [categoriesLoaded, setCategoriesLoaded] = useState(false);
  const [colorsInserted, setColorsInserted] = useState(false);
  const [user, setUser] = useState(undefined);
  const [hideSidebar, setHideSidebar] = useState(true);
  const auth = getAuth();

  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname; // Get current path
    if (
      !currentPath.includes("/timeblocks") &&
      !currentPath.includes("/activities")
    ) {
      setHideSidebar(true);
    } else {
      setHideSidebar(false);
    }
  }, [location]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    if (activitiesLoaded && categoriesLoaded && colorsInserted) {
      setInitialDataLoaded(true);
    }
  }, [activitiesLoaded, categoriesLoaded, colorsInserted]);

  useEffect(() => {
    if (
      localStorage.getItem("theme") === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, []);

  useEffect(() => {
    if (activitiesLoaded && categoriesLoaded) {
      setActivities((currentActivities) => {
        return currentActivities.map((activity) => {
          if (activity.type === "Categories" || activity.hasCustomColor) {
            return activity;
          } else {
            const category = currentActivities.find(
              (a) => a.id === activity.categoryId
            );
            return { ...activity, color: category?.color };
          }
        });
      });
      setColorsInserted(true);
    }
  }, [activitiesLoaded, categoriesLoaded]);

  useEffect(() => {
    if (!user) return;
    GetCategories().then((response) => {
      setActivities((currentActivities) => {
        const updatedValues = [
          ...currentActivities,
          ...response.data.categories.map((category) => ({
            ...category,
            type: "Categories",
          })),
        ];
        return updatedValues;
      });
      setCategoriesLoaded(true);
    });

    GetActivities().then((response) => {
      setActivities((currentActivities) => {
        return [...currentActivities, ...response.data.activities];
      });
      setActivitiesLoaded(true);
    });
  }, [user]);

  const [date, setDate] = useState(new Date());
  const [dateCount, setDateCount] = useState(null);

  return (
    <div className="min-h-screen bg-primaryLight dark:bg-lightDark text-white">
      {/* <Header /> */}
      <div className="flex">
        <UserContext.Provider value={[user, setUser]}>
          <ActivityContext.Provider value={[activities, setActivities]}>
            <InitialDataLoadedContext.Provider
              value={[initialDataLoaded, setInitialDataLoaded]}
            >
              <DateContext.Provider value={[date, setDate]}>
                <DateCountContext.Provider value={[dateCount, setDateCount]}>
                  {!hideSidebar && <SideMenu />}

                  <div className="flex-grow">
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/home" element={<Home />} />
                      <Route path="/login" element={<Login />} />
                      <Route
                        path="/timeblocks"
                        element={
                          <ProtectedRoute user={user}>
                            <TimeBlocks />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/activities"
                        element={
                          <ProtectedRoute user={user}>
                            <Activities />
                          </ProtectedRoute>
                        }
                      />
                    </Routes>
                  </div>
                </DateCountContext.Provider>
              </DateContext.Provider>
            </InitialDataLoadedContext.Provider>
          </ActivityContext.Provider>
        </UserContext.Provider>
      </div>
    </div>
  );
}

const ProtectedRoute = ({ user, children }) => {
  if (user === undefined) {
    return null;
  } else if (user === null) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

async function GetCategories() {
  return await axiosInstance.get("/categorys");
}
async function GetActivities() {
  return await axiosInstance.get("/activitys");
}

export default App;
