import { useContext, useEffect, useState } from "react";
import { UserContext } from "../App";
import { signOut } from "firebase/auth";
import { Link, Navigate } from "react-router-dom";
import { auth } from "../firebase";
import { HeaderNavigationMenu } from "../appComponents/HeaderNavigationMenu";

export function HomeHeader() {
  const [user, setUser] = useContext(UserContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = (e) => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleLogout = () => {
    signOut(auth);
  };

  // if (user) return <></>;

  return (
    <div className="py-5 md:py-7">
      <div className="relative px-3 sm:p-0 flex gap-5 sm:justify-center">
        <Link className="opacity-70 text-white" to="/home">
          Home
        </Link>
        <Link className="opacity-70 text-white" to="/timeblocks">
          Time Blocks
        </Link>
        <Link className="opacity-70 text-white" to="/activities">
          Activities
        </Link>
        <div className="absolute right-3 sm:right-5">
          {!user && (
            <Link className="text-white" to="/login">
              Sign in
            </Link>
          )}
          {user && (
            <button className="opacity-70" onClick={handleLogout}>
              Sign out
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
