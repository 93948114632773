import { useContext, useEffect, useState } from "react";
import { DateChooser } from "./DateChooser";
import { DateContext, UserContext } from "../App";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { ColumnViewPicker } from "./columnViewPicker";
import { HeaderNavigationMenu } from "./HeaderNavigationMenu";

export function TimeBlocksHeader() {
  const [date, setDate] = useContext(DateContext);
  const [user, setUser] = useContext(UserContext);
  const auth = getAuth();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = (e) => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();
  const handleLogout = () => {
    signOut(auth);
    navigate("/login");
  };

  const handleSetDateToday = () => {
    setDate(new Date());
  };
  return (
    <div className="px-2 py-5 md:px-12 md:py-7 flex items-center gap-3">
      <button
        className="border  px-6 rounded-md py-1 text-center text-black border-black dark:text-white dark:border-white"
        onClick={handleSetDateToday}
      >
        Today
      </button>
      <DateChooser />
      <ColumnViewPicker />
      {windowWidth < 600 && (
        <div className="ml-auto">
          <HeaderNavigationMenu />
        </div>
      )}
    </div>
  );
}
