export function getWeekDates(inputDate) {
  let date = new Date(inputDate); // Make sure it's a Date object.

  // Adjust to the previous Monday or stay on the current day if it's already Monday
  let day = date.getUTCDay();
  let difference = day === 0 ? -6 : 1 - day; // If it's Sunday, go to the previous Monday, otherwise adjust to the nearest Monday.

  date.setUTCDate(date.getUTCDate() + difference); // Adjust to Monday

  let weekDates = [];
  for (let i = 0; i < 7; i++) {
    weekDates.push(new Date(date)); // Store a copy of the date
    date.setUTCDate(date.getUTCDate() + 1); // Move to the next day
  }

  return weekDates;
}

export function daysFromNow(numDays) {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + numDays);
  return currentDate;
}
export function daysFromDate(date, numDays) {
  var newDate = new Date(date.getTime());

  newDate.setDate(newDate.getDate() + numDays);
  return newDate;
}

export function getDatesBySetting(inputDate, setting) {
  let date = new Date(inputDate);
  let resultDates = [];

  switch (setting) {
    case "day":
      resultDates.push(new Date(date));
      break;
    case "3d":
      date.setDate(date.getDate() - 1); // Move to the previous day
      for (let i = 0; i < 3; i++) {
        resultDates.push(new Date(date));
        date.setDate(date.getDate() + 1); // Move to the next day
      }
      break;
    case "week":
      let day = date.getUTCDay();
      let difference = day === 0 ? -6 : 1 - day;
      date.setUTCDate(date.getUTCDate() + difference); // Adjust to Monday
      for (let i = 0; i < 7; i++) {
        resultDates.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
      break;
    case "month":
      let month = date.getMonth();
      date.setDate(1);
      while (date.getMonth() === month) {
        resultDates.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
      break;
    default:
      throw new Error("Invalid setting provided.");
  }

  return resultDates;
}

export function dateToNumber(date) {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0, so we add 1
  const dd = String(date.getDate()).padStart(2, "0");

  return Number(`${yyyy}${mm}${dd}`);
}
