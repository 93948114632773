import { Listbox, Transition } from "@headlessui/react";
import Modal from "./Modal";
import { Fragment, useEffect, useState } from "react";
import { TextInput } from "./TextInput";

export default function EditCategoryModal({ onEditCategory, category }) {
  const handleEditCategory = () => {
    const updatedActivity = {
      ...category,
      title,
      color,
    };

    onEditCategory(updatedActivity);
  };

  const [title, setTitle] = useState(null);
  const [color, setColor] = useState(null);
  const [hasValues, setHasValues] = useState(false);

  useEffect(() => {
    if (category == null) return;

    setTitle(category.title);
    setColor(category.color);
    setHasValues(true);
  }, [category]);

  const handleTitleChange = (value) => {
    setTitle(value);
  };

  const handleColorChange = (event) => {
    setColor(event.target.value);
  };

  if (!hasValues) return "";

  return (
    <Modal
      title={"Edit Categroy"}
      successText={"Save"}
      onSuccess={handleEditCategory}
    >
      <form className="text-white space-y-3">
        <TextInput
          title={"Title"}
          value={title}
          placeholder={"Category_1"}
          onValueChange={handleTitleChange}
        />

        <input
          type="color"
          value={color}
          onChange={handleColorChange}
          className="border-0 rounded bg-none"
        />
      </form>
    </Modal>
  );
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function SelectActivity({ children, selected, onSelectedChange }) {
  const [activities, setActivities] = useState([
    "Critical & Immediate",
    "Routine Activites",
    "Personal Projects & Initiatives",
    // "Categories",
  ]);

  return (
    <Listbox value={selected} onChange={onSelectedChange}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium leading-6 text-white">
            Type
          </Listbox.Label>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-lightDark py-1.5 pl-3 pr-10 text-left text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
              <span className="flex items-center">
                <span className="ml-3 block truncate">{selected}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2"></span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-lightDark  py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {activities.map((activity) => (
                  <Listbox.Option
                    key={activity}
                    className={({ active }) =>
                      classNames(
                        "text-white relative cursor-default select-none py-2 pl-3 pr-9 hover:bg-dark"
                      )
                    }
                    value={activity}
                  >
                    {activity}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

function CheckboxInput({ title, onValuehange, value }) {
  const onValueChange = (input) => {
    onValuehange(input.target.value);
  };
  return (
    <div className="flex items-start mb-6">
      <div className="flex items-center h-5">
        <input
          type="checkbox"
          checked={value == "true"}
          onChange={onValueChange}
        />
      </div>
      <label className="ml-2 text-sm font-medium text-gray-300">{title}</label>
    </div>
  );
}

function RadioInput({ selectedColorWay, onColorWayChange }) {
  const onRadioChange = (event) => {
    onColorWayChange(event.target.value);
  };

  return (
    <div>
      <h3 className="mb-2 text-sm font-medium leading-6">Color</h3>
      <ul className="p-2 rounded-xl grid w-full gap-6 md:grid-cols-2 bg-lightDark">
        <li>
          <input
            type="radio"
            id="color-category"
            name="color"
            value="category"
            className="hidden peer"
            onChange={onRadioChange}
            checked={selectedColorWay === "category"} // Check if this option is selected
          />
          <label
            htmlFor="color-category"
            className="flex justify-center w-full px-2 py-1 text-white bg-lightDark rounded-lg cursor-pointer  peer-checked:text-black peer-checked:bg-white hover:text-white hover:bg-dark "
          >
            <div className="block">
              <div className="w-full font-semibold">Category</div>
            </div>
          </label>
        </li>
        <li>
          <input
            type="radio"
            id="color-custom"
            name="color"
            value="custom"
            className="hidden peer"
            onChange={onRadioChange}
            checked={selectedColorWay === "custom"} // Check if this option is selected
          />
          <label
            htmlFor="color-custom"
            className="flex justify-center w-full px-2 py-1 text-white bg-lightDark rounded-lg cursor-pointer  peer-checked:text-black peer-checked:bg-white hover:text-white hover:bg-dark "
          >
            <div className="block">
              <div className="w-full font-semibold ">Custom</div>
            </div>
          </label>
        </li>
      </ul>
    </div>
  );
}

function Category({ category, selectedCategory, onSelect }) {
  const onSelectCategory = () => {
    if (category && category?._id === selectedCategory?._id) {
      onSelect(null);
    } else {
      onSelect(category);
    }
  };

  return (
    <button
      type="button"
      onClick={onSelectCategory}
      className={[
        "px-3 py-1 rounded-md cursor-pointer inline-flex items-center gap-2 hover:bg-lightDark",
        category._id === selectedCategory?._id ? "bg-lightDark" : "",
      ].join(" ")}
    >
      <div
        className="w-2 h-2"
        style={{ backgroundColor: category.color }}
      ></div>
      <h4 className="text-sm text-white">{category.title}</h4>
    </button>
  );
}
