import { useContext, useEffect, useState } from "react";
import { DateCountContext } from "../App";
import "./columnViewPicker.css";

export function ColumnViewPicker() {
  const [dateCount, setDateCount] = useContext(DateCountContext);

  const handleDateCountChange = (event) => {
    setDateCount(Number(event.target.value));
  };

  const handleCountChange = (value) => {
    setDateCount(value);
    setDateCount(value);
    setDateCount(value);
  };

  if (window.innerWidth < 860) {
    return (
      <div>
        <SelectCount dateCount={dateCount} onCountChange={handleCountChange} />
      </div>
    );
  }

  return (
    <div className="px-4 py-2 rounded bg-se bg-secondaryLight dark:bg-dark">
      <ul className="flex gap-2">
        <li>
          <input
            type="radio"
            id="1day"
            name="1day"
            value={1}
            className="hidden peer"
            checked={dateCount === 1}
            onChange={handleDateCountChange}
          />
          <label
            htmlFor="1day"
            className="px-2 py-1 text-sm rounded cursor-pointer text-black peer-checked:bg-black peer-checked:text-white dark:text-white dark:peer-checked:bg-white dark:peer-checked:text-black"
          >
            1 Day
          </label>
        </li>
        <li>
          <input
            type="radio"
            id="3day"
            name="3day"
            value={3}
            className="hidden peer"
            checked={dateCount === 3}
            onChange={handleDateCountChange}
          />
          <label
            htmlFor="3day"
            className="px-2 py-1 text-sm rounded cursor-pointer text-black peer-checked:bg-black peer-checked:text-white dark:text-white dark:peer-checked:bg-white dark:peer-checked:text-black"
          >
            3 Days
          </label>
        </li>
        <li>
          <input
            type="radio"
            id="week"
            name="week"
            value={7}
            className="hidden peer"
            checked={dateCount === 7}
            onChange={handleDateCountChange}
          />
          <label
            htmlFor="week"
            className="px-2 py-1 text-sm rounded cursor-pointer text-black peer-checked:bg-black peer-checked:text-white dark:text-white dark:peer-checked:bg-white dark:peer-checked:text-black"
          >
            Week
          </label>
        </li>
      </ul>
    </div>
  );
}

export function SelectCount({ children, dateCount, onCountChange }) {
  const [selectedCount, setSelectedCount] = useState(7);
  useEffect(() => {
    setSelectedCount(dateCount);
  }, [dateCount]);
  const handleCountChange = (e) => {
    setSelectedCount(e.target.value);
    onCountChange(e.target.value);
  };

  return (
    <div className="custom-select">
      <select
        value={selectedCount ?? ""}
        onChange={(e) => handleCountChange(e)}
        className="border-none"
      >
        <option value={1}>1 Day</option>
        <option value={3}>3 Day</option>
        <option value={7}>Week</option>
      </select>
    </div>
  );
}
