import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import axiosInstance from "./httpInterceptor";

const firebaseConfig = {
  apiKey: "AIzaSyBvr4_T3C1vNJ3kAuoPMIWJaKfbOVOEZ0w",
  authDomain: "timeblocker-c72d3.firebaseapp.com",
  projectId: "timeblocker-c72d3",
  storageBucket: "timeblocker-c72d3.appspot.com",
  messagingSenderId: "71238432490",
  appId: "1:71238432490:web:ff25009436dc457c5fc993",
  measurementId: "G-T3MSFTNL1L",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const provider = new GoogleAuthProvider();

export const signInWithGoogle = () => {
  return signInWithPopup(auth, provider)
    .then((result) => {
      const user = result.user;
      return user.getIdToken();
    })
    .then(() => {
      return axiosInstance.post("/users/googleLogin");
    })
    .catch((error) => {});
};
