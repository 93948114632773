import { forwardRef, useContext, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendar from "../assets/icons/calendar-white.svg";
import calendarBlack from "../assets/icons/calendar-black.svg";
import { daysFromDate } from "../Functions/BlockPosition/ColumnDate";
import arrowLeft from "../assets/icons/arrow-left-white.svg";
import arrowLeftBlack from "../assets/icons/arrow-left-black.svg";
import arrowRight from "../assets/icons/arrow-right-white.svg";
import arrowRightBlack from "../assets/icons/arrow-right-black.svg";
import { DateContext, DateCountContext } from "../App";
// import { useContext } from "react";

export function DateChooser() {
  const [date, setDate] = useContext(DateContext);
  const [dateCount, setDateCount] = useContext(DateCountContext);

  const handlePreviousDate = () => {
    const newDate = daysFromDate(date, -dateCount);
    setDate(newDate);
  };
  const handleNextDate = () => {
    const newDate = daysFromDate(date, dateCount);
    setDate(newDate);
  };

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  return (
    <div className="flex">
      <button
        className="p-1 border rounded-l border-black dark:border-white "
        onClick={handlePreviousDate}
      >
        <img className="hidden dark:block" src={arrowLeft} alt="" />
        <img className="dark:hidden" src={arrowLeftBlack} alt="" />
      </button>
      <div className="p-1 border-t border-b border-black dark:border-white">
        <DatePickerComp startDate={date} onDateChange={handleDateChange} />
      </div>
      <button
        className="p-1 border rounded-r border-black dark:border-white"
        onClick={handleNextDate}
      >
        <img className="hidden dark:block" src={arrowRight} alt="" />
        <img className="dark:hidden" src={arrowRightBlack} alt="" />
      </button>
    </div>
  );
}

export function DatePickerComp({ startDate, onDateChange }) {
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="rounded bg-" onClick={onClick} ref={ref}>
      <img className="hidden dark:block" src={calendar} alt="" />
      <img className="dark:hidden" src={calendarBlack} alt="" />
    </button>
  ));

  return (
    <ReactDatePicker
      selected={startDate}
      onChange={(date) => onDateChange(date)}
      customInput={<ExampleCustomInput />}
    />
  );
}
