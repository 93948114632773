import { Block } from "./Block";

export function Column({
  id,
  blocks,
  column,
  adjustingBlock,
  showBlocks,
  onStartDrag,
  onEnterSegment,
  onStartMove,
  onStartResize,
  onDelete,
  onDrop,
  onRemoveActivity,
  onTitleChange,
  onColorChange,
  onRecurringChange,
  onPatternChange,
  onChangeAsRecurringChange,
  updatingBlockIds,
  updatingRecurranceIds,
}) {
  const onEnter = (segmentIndex) => {
    onEnterSegment(segmentIndex, id);
  };
  const onMouseDown = (segmentIndex) => {
    onStartDrag(segmentIndex, id);
  };

  const onMove = (block) => {
    onStartMove(id, block);
  };
  const onResize = (block, resizingSide) => {
    onStartResize(id, block, resizingSide);
  };
  const onDeleteBlock = (block) => {
    onDelete(id, block);
  };

  const handleDrop = (activityId, blockId) => {
    onDrop(activityId, blockId, id);
  };
  const handleTitleChange = (block, title) => {
    onTitleChange(id, block, title);
  };
  const handleRemoveActivity = (block) => {
    onRemoveActivity(id, block);
  };
  const handleColorChange = (block, color) => {
    onColorChange(id, block, color);
  };

  return (
    <div>
      <div className="flex flex-col w-full relative" draggable="false">
        {renderSegments(onMouseDown, onEnter)}
        {renderBlocks(
          blocks,
          adjustingBlock,
          showBlocks,
          onMove,
          onResize,
          onDeleteBlock,
          handleDrop,
          handleRemoveActivity,
          handleTitleChange,
          handleColorChange,
          onRecurringChange,
          onPatternChange,
          onChangeAsRecurringChange,
          updatingBlockIds,
          updatingRecurranceIds
        )}
      </div>
    </div>
  );
}

function renderSegments(onMouseDown, onEnter) {
  const segments = [];
  for (let i = 0; i < 24 * 4; i++) {
    segments.push(
      <div
        onMouseDown={(e) => {
          if (e.button === 0) onMouseDown(i);
        }}
        onMouseEnter={() => onEnter(i)}
        draggable="false"
        key={i}
        className={[
          "w-full h-3 cursor-pointer select-none ",
          i % 4 === 0 ? "border-t border-light" : "",
        ].join(" ")}
      >
        &nbsp;
      </div>
    );
  }
  return segments;
}

function renderBlocks(
  blocks,
  adjustingBlock,
  showBlocks,
  onMove,
  onResize,
  onDeleteBlock,
  handleDrop,
  onRemoveActivity,
  onTitleChange,
  handleColorChange,
  onRecurringChange,
  onPatternChange,
  onChangeAsRecurringChange,
  updatingBlockIds,
  updatingRecurranceIds
) {
  if (!showBlocks) return <></>;
  return blocks.map((block) => {
    const isUpdating = block.isRecurrance
      ? updatingRecurranceIds[block.recurranceId] ?? false
      : updatingBlockIds[block.id] ?? false;
    return (
      <Block
        key={block.id}
        block={block}
        isSolid={adjustingBlock !== block.id}
        triggerEvents={adjustingBlock !== null}
        onMove={onMove}
        onResize={onResize}
        onDeleteBlock={onDeleteBlock}
        onDrop={handleDrop}
        onRemoveActivity={onRemoveActivity}
        onTitleChange={onTitleChange}
        onColorChange={handleColorChange}
        onRecurringChange={onRecurringChange}
        onPatternChange={onPatternChange}
        onChangeAsRecurringChange={onChangeAsRecurringChange}
        isUpdating={isUpdating}
      />
    );
  });
}
