export function TextInput({ title, placeholder, onValueChange, value = "" }) {
  const tc = (input) => {
    onValueChange(input.target.value);
  };
  return (
    <div>
      <label
        htmlFor="price"
        className="block text-sm font-medium leading-6 white"
      >
        {title}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <input
          type="text"
          name={title}
          value={value}
          id="text"
          className="block bg-lightDark w-full rounded-md border-0 py-1.5 pl-7 p-6 text-gray-100 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
          placeholder={placeholder}
          onChange={tc}
        />
      </div>
    </div>
  );
}
